import { ReactElement } from 'react'
import styled from 'styled-components'

import { BlockDescriptor } from '~/components/atoms'
import { ArticleCard_Hero } from '~/components/molecules'
import type { ArticleSummaryData } from '~/types/content'

import { TopList } from './molecules'

//----- Styling -----//

const TopArea = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		flex-direction: row;
		border-bottom: none;
	}
`

const HeroColumn = styled.div`
	flex: 1 1 60%;
	align-self: center;

	border-bottom: 1px solid ${(p) => p.theme.color.gray[67]};

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		margin-left: 2.5em;
		padding-right: 1em;

		border-right: 1px solid ${(p) => p.theme.color.gray[67]};
		border-bottom: 6px solid ${(p) => p.theme.color.primary.contrast};
	}
`

const ListColumn = styled.div`
	flex: 1 1 30%;
	align-self: center;
	max-width: 600px;
	margin-top: 1em;
	padding-bottom: 2em;

	border-bottom: 6px solid ${(p) => p.theme.color.primary.contrast};

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		align-self: auto;
		border-bottom: none;
	}
`

//----- Component -----//

export const TopStories = (props: {
	articles?: ArticleSummaryData[]
}): ReactElement | null => {
	if (!props.articles) return null

	// Clone to ensure array shift doesn't impact the source
	const articles = [...props.articles]

	return (
		<>
			<BlockDescriptor marginTop marginBottom>
				Today&apos;s Top Stories
			</BlockDescriptor>
			<TopArea>
				<HeroColumn>
					<ArticleCard_Hero article={articles.shift()} />
				</HeroColumn>
				<ListColumn>
					<TopList articles={articles} />
				</ListColumn>
			</TopArea>
		</>
	)
}
