import { ReactElement } from 'react'
import Head from 'next/head'

//----- Component -----//

interface MetaTagsProps {
	canonicalUrl: string
}

export const MetaTags = (props: MetaTagsProps): ReactElement => {
	return (
		<Head>
			<link rel="canonical" href={props.canonicalUrl} />
		</Head>
	)
}
