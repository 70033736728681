import { ReactElement } from 'react'
import styled from 'styled-components'

import { ArticleCard_TextOnly } from '~/components/molecules'
import type { ArticleSummaryData } from '~/types/content'

//----- Styling -----//

const List = styled.ul`
	margin: 0 auto;
	padding: 0;

	list-style: none;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		margin: 0 2.5em 0 0;
	}
`

const ListItem = styled.li`
	padding: 7px 0;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		padding: 0 1em;

		&::after {
			content: '';
			display: inline-block;
			border-bottom: 1px solid rgb(170, 170, 170);
			width: 100%;
			max-width: 15%;
			margin-left: -1em;
		}
	}
`

//----- Component -----//

export const TopList = (props: {
	articles: ArticleSummaryData[]
}): ReactElement => {
	return (
		<List>
			{props.articles.map((article, i) => {
				return (
					<ListItem key={i}>
						<ArticleCard_TextOnly article={article} />
					</ListItem>
				)
			})}
		</List>
	)
}
