import { ReactElement } from 'react'

import { SiteConfig } from '~/config'
import { useAppContext } from '~/contexts'

import { FormButton, HeavyAnchor, ViewTitle } from '../../atoms'

//----- Component -----//

export const RejectedView = (props: { tryAgain: () => void }): ReactElement => {
	const app = useAppContext()
	const { externalUrls } = SiteConfig

	function handleLogin() {
		app.setRegisterModalOpen(false)
		app.setLoginModalOpen(true)
	}

	function handleRestart() {
		props.tryAgain()
	}

	return (
		<>
			<ViewTitle>Existing Account Found</ViewTitle>
			<div>
				Our records show there is already an account created for the email
				address you provided.
			</div>
			<ul style={{ paddingLeft: '1.25em' }}>
				<li>
					If you know your password,{' '}
					<HeavyAnchor onClick={handleLogin}>log in now</HeavyAnchor>.
				</li>
				<li>
					If you have forgotten your password,{' '}
					<HeavyAnchor to={externalUrls.resetPassword}>click here</HeavyAnchor>.
				</li>
			</ul>
			<FormButton onClick={handleRestart} variant="contrast">
				Restart Registration
			</FormButton>
		</>
	)
}
