import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'

//----- Styling -----//

const Node = styled.ul`
	margin: 1em 0;
`

//----- Component -----//

export const UnorderedList = (
	props: JSX.IntrinsicElements['ul'] & ExtraProps
): JSX.Element => {
	const { node, ref, ...rest } = props

	return <Node {...rest} />
}
