import { ReactElement } from 'react'
import styled from 'styled-components'

import { usePageContext } from '~/contexts'

//----- Styling -----//

const Announce = styled.div`
	padding: 4px 8px;

	color: ${(p) => p.theme.color.primary.base};
	font-size: 15px;
	font-family: ${(p) => p.theme.font.sans.family};
	font-weight: ${(p) => p.theme.font.sans.weight.bold};

	line-height: 1;
	text-align: center;
	text-transform: uppercase;

	background: ${(p) => p.theme.color.primary.accent};
	border-style: solid;
	border-color: ${(p) => p.theme.color.primary.contrast};
	border-width: 2px 0 3px;

	a {
		color: ${(p) => p.theme.color.primary.base};
		position: relative;
		padding-right: 20px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 6px;
			height: 6px;
			bottom: 7px;
			right: 7px;
			transform: rotate(45deg);

			border: 1px solid ${(p) => p.theme.color.primary.base};
			border-width: 2px 2px 0 0;
		}
		&:after {
			right: 2px;
		}
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		border-top: 0;
	}
`

//----- Component -----//

export const AnnounceRibbon = (): ReactElement | null => {
	const { link, text } = usePageContext().announce

	if (!text) return null

	return (
		<Announce>
			<span>{link ? <a href={link}>{text}</a> : text}</span>
		</Announce>
	)
}
