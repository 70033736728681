import { ReactElement } from 'react'

import { MetaTags } from './atoms'

//----- Component -----//

export const ListingMeta = (props: { canonicalUrl: string }): ReactElement => {
	const { canonicalUrl } = props

	const basicInfo = {
		canonicalUrl
	}

	return (
		<>
			<MetaTags {...basicInfo} />
		</>
	)
}
