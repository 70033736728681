import { ReactElement } from 'react'
import styled from 'styled-components'

import { IssueArticle } from '../atoms'
import type { ArticleSummaryData } from '~/types/content'

//----- Styling -----//

const ArticleList = styled.ul`
	margin: 0.5em 0;
	padding: 0;
	max-width: 380px;
`

//----- Component -----//

export const IssueArticles = (props: {
	articles: ArticleSummaryData[]
}): ReactElement => {
	return (
		<ArticleList>
			{props.articles?.map((article, i) => (
				<IssueArticle key={i} article={article} />
			))}
		</ArticleList>
	)
}
