import React from 'react'

import { MetaTags } from './atoms'
import { SiteConfig } from '~/config'

//----- Component -----//

export const HomeMeta: React.FC = () => {
	const canonicalUrl = SiteConfig.siteInfo.rootUrl

	return (
		<>
			<MetaTags url={canonicalUrl} />
		</>
	)
}
