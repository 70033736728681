import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'

//----- Styling -----//

const Node = styled.strong`
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

export const Strong = (
	props: JSX.IntrinsicElements['strong'] & ExtraProps
): JSX.Element => {
	const { node, ref, ...rest } = props

	return <Node {...rest} />
}
