import { Fragment, ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { ArticleCard } from '~/components/molecules'
import type { ArticleSummaryData } from '~/types/content'

//----- Styling -----//

const GridContainer = styled.div<{ wideThumbs?: boolean }>`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
	grid-gap: 1rem;

	${(p) =>
		p.wideThumbs &&
		css`
			@media (min-width: 711px) {
				grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
			}
		`}
`

//----- Component -----//

type ArticleGridProps = {
	articles?: ArticleSummaryData[]
	wideThumbs?: boolean
	showAdAfter?: never
	adUnitName?: never
}

export const ArticleGrid = (props: ArticleGridProps): ReactElement => {
	return (
		<GridContainer wideThumbs={props.wideThumbs}>
			{props.articles &&
				props.articles.map((article, i) => (
					<Fragment key={i}>
						<ArticleCard article={article} />
					</Fragment>
				))}
		</GridContainer>
	)
}
