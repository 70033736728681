import { ReactElement } from 'react'
import styled from 'styled-components'

import type { TagData } from '~/types/content'

import { Tag } from './atoms'

//----- Styling -----//

const StyledTagList = styled.ul`
	margin: 2em 0 0;
	padding: 0;
`

//----- Component -----//

export const TagList = (props: { tags?: TagData[] }): ReactElement | null => {
	if (!props.tags) return null

	return (
		<StyledTagList>
			{props.tags.map((tag, index) => (
				<Tag tag={tag} key={index} />
			))}
		</StyledTagList>
	)
}
