import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import styled from 'styled-components'

// Include PDF Service Worker from CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

import { LoadingSpinner_Ring } from '~/components/atoms'
import { Modal } from '~/components/molecules'
import { useAppContext } from '~/contexts'

import { PageLabel } from './atoms'
import { ControlButtons, PageButtons } from './molecules'

//----- Definitions -----//

const MAX_ZOOM = 2
const MIN_ZOOM = 0.5
const ZOOM_STEP = 0.25

//----- Styling -----//

const ReaderContainer = styled.div`
	overflow: auto;
	height: calc(100% - 2.75em);
	background: ${(p) => p.theme.color.gray[74]};
	margin: 0.5em -1em 0;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.sm}) {
		border: 1px solid ${(p) => p.theme.color.gray[38]};
		height: calc(100% - 1.75em);
	}

	canvas {
		margin: 0 auto;
	}
`

const LoadingSpinner = styled(LoadingSpinner_Ring)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

//----- Component -----//

export const ReaderModal: React.FC = () => {
	const [numPages, setNumPages] = useState(0)
	const [pageNumber, setPageNumber] = useState(1)

	const [pageScale, setPageScale] = useState(1)
	const [scrollbarWidth, setScrollbarWidth] = useState(0)

	const readerRef = useRef<HTMLDivElement>(null)

	const { readerModalIsOpen, readerTarget } = useAppContext()

	const path = `/api/archive${readerTarget}`

	function onDocumentLoadSuccess(pdf: { numPages: number }) {
		setNumPages(pdf.numPages)
		setPageNumber(1)
	}

	function zoomPage(zoomIn = false) {
		let newScale = zoomIn ? pageScale + ZOOM_STEP : pageScale - ZOOM_STEP

		if (newScale < MIN_ZOOM) newScale = MIN_ZOOM
		if (newScale > MAX_ZOOM) newScale = MAX_ZOOM

		setPageScale(newScale)
	}

	function changePage(offset: number) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset)
	}

	function checkPageSize() {
		if (!readerRef.current) return setScrollbarWidth(0)

		// Calculate width of scrollbar
		const { offsetWidth, clientWidth } = readerRef.current
		setScrollbarWidth(offsetWidth - clientWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', checkPageSize)

		return () => window.removeEventListener('resize', checkPageSize)
	}, [readerTarget])

	return (
		<Modal fullScreen isOpen={readerModalIsOpen}>
			{readerTarget && (
				<ReaderContainer ref={readerRef}>
					<Document
						file={path}
						loading={<LoadingSpinner size={64} />}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							pageNumber={pageNumber}
							renderTextLayer={false}
							scale={pageScale}
							onRenderSuccess={checkPageSize}
						/>
					</Document>
				</ReaderContainer>
			)}
			<PageLabel currentPage={pageNumber} totalPages={numPages} />
			<PageButtons
				currentPage={pageNumber}
				totalPages={numPages}
				scrollbarWidth={scrollbarWidth}
				changePage={changePage}
			/>
			<ControlButtons
				onZoomIn={() => zoomPage(true)}
				onZoomOut={() => zoomPage(false)}
				downloadPath={path}
			/>
		</Modal>
	)
}
