import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const Node = styled.span`
	display: block;
	margin: 1em 0;
	font-weight: 700;
	color: hsl(100deg, 90%, 30%);
`

//----- Component -----//

export const Unsupported = (props: RichTextContent): ReactElement => (
	<>
		<Node>Unsupported Node: {props.nodeType}</Node>
		<ContentAsReact content={props.content} />
	</>
)
