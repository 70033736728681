import { ReactElement } from 'react'

import { NewsletterForm } from '~/components/molecules'
import { SiteConfig } from '~/config'
import { useUserContext } from '~/contexts'

import { Paragraph, PromptAnchor } from '../atoms'

//----- Component -----//

export const NewsletterBonus = (): ReactElement | null => {
	const user = useUserContext()

	// No need to prompt a subscribe if they're subscribed
	if (user.hasNewsletterSub) return null

	return (
		<>
			<Paragraph>
				Sign up for our weekly newsletter and unlock one more article for free.
			</Paragraph>
			<NewsletterForm signupSource="Paywall" invertedMessages />
			<Paragraph>
				View our{' '}
				<PromptAnchor to={SiteConfig.externalUrls.privacyPolicy}>
					Privacy Policy
				</PromptAnchor>
			</Paragraph>
		</>
	)
}
