import styled from 'styled-components'

import { Button_Solid } from '~/components/atoms'

//----- Styling -----//

export const FormButton = styled(Button_Solid)`
	margin: 1em 0 0.75em;
	width: 100%;

	button + & {
		margin-top: 0.25em;
	}
`
