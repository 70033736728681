import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor, Image_Contentful } from '~/components/atoms'
import type { IssueData } from '~/types/content'
import { getPath } from '~/util'

import { ReadIssue } from '../atoms'

//----- Styling -----//

const Card = styled.div<{ isActive?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 330px;

	text-align: center;

	${(p) => (p.isActive ? 'transform: scale(1.15);' : 'img { opacity: 1.0; }')}

	p {
		margin: 0.5em;
	}
`

//----- Component -----//

export const IssueCard = (props: {
	isActive?: boolean
	issue: IssueData
}): ReactElement => {
	const { isActive, issue } = props

	const path = getPath.issue(issue.slug, issue.publication.date)

	const year = issue.publication.date
		? `, ${new Date(issue.publication.date).getUTCFullYear()}`
		: null

	return (
		<Card isActive={isActive}>
			<Anchor to={path}>
				{issue.coverImage?.url && (
					<Image_Contentful
						src={issue.coverImage.url}
						alt={`Cover for the ${issue.publication.period} issue`}
						maxWidth={145}
					/>
				)}
				<p>
					{issue.publication.period}
					{year}
				</p>
			</Anchor>
			<ReadIssue archive={issue.archive} />
		</Card>
	)
}
