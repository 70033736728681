import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import { ChevronLeft, ChevronRight } from '@styled-icons/material'

//----- Styling -----//

const Button = styled.button<{
	action: 'next' | 'prev'
	rightOffset?: number
}>`
	position: absolute;
	top: 50%;
	${(p) => (p.action === 'prev' ? 'left: 0;' : `right: ${p.rightOffset}px;`)}
	transform: translateY(-50%);

	padding: 2em 0;

	color: white;
	background: ${(p) => fade(0.1, p.theme.color.gray[38])};
	border: 0;
	cursor: pointer;

	&:focus {
		outline: 2px solid ${(p) => p.theme.color.primary.contrast};
		outline-offset: -2px;
	}

	&:active,
	&:hover {
		background: ${(p) => fade(0.1, p.theme.color.gray[46])};
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.sm}) {
		padding: 2em 0;
		${(p) =>
			p.action === 'prev'
				? 'left: 1em;'
				: `right: calc(1em + ${p.rightOffset}px);`}
	}
`

//----- Component -----//

export const PageButtons: React.FC<{
	currentPage: number
	totalPages: number
	scrollbarWidth: number
	changePage: (offset: number) => void
}> = ({ currentPage, totalPages, scrollbarWidth, changePage }) => (
	<>
		{currentPage > 1 && (
			<Button
				action="prev"
				aria-label="Previous Page"
				onClick={() => changePage(-1)}
			>
				<ChevronLeft size={46} />
			</Button>
		)}
		{currentPage < totalPages && (
			<Button
				action="next"
				aria-label="Next Page"
				rightOffset={scrollbarWidth}
				onClick={() => changePage(1)}
			>
				<ChevronRight size={46} />
			</Button>
		)}
	</>
)
