import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'

//----- Styling -----//

const Node = styled.ol`
	margin: 1em 0;
`

//----- Component -----//

export const OrderedList = (
	props: JSX.IntrinsicElements['ol'] & ExtraProps
): JSX.Element => {
	const { node, ref, ...rest } = props

	return <Node {...rest} />
}
