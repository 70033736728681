import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/material'
import { transparentize } from 'polished'

import { Anchor } from '~/components/atoms'

//----- Styling -----//

const ListItem = styled.li.attrs(({ theme }) => ({
	borderColor: transparentize(0.75, theme.color.primary.base)
}))`
	padding: 0.5em 0;

	border-top: 1px solid ${(p) => p.borderColor};

	&:last-child {
		border-bottom: 1px solid ${(p) => p.borderColor};
	}

	& > a {
		display: flex;
		align-items: center;

		color: ${(p) => p.theme.color.primary.base};
		text-transform: uppercase;
		text-decoration: none;
	}
`

const Content = styled.div`
	display: inline-block;
	flex-grow: 1;
`

const Icon = styled.div`
	display: inline-block;
	width: 24px;
`

//----- Component -----//

interface Props {
	href: string
	children: ReactNode
}

export const SlideoutItem = (props: Props): ReactElement => {
	return (
		<ListItem>
			<Anchor to={props.href}>
				<Content>{props.children}</Content>
				<Icon>
					<ChevronRight />
				</Icon>
			</Anchor>
		</ListItem>
	)
}
