import { ReactElement } from 'react'
import styled from 'styled-components'
import { Home } from '@styled-icons/material'

import { Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { useThemeContext } from '~/contexts'

const Link = styled(Anchor)`
	display: inline-block;
	margin-top: 1em;
`

const HomeIcon = () => {
	const { color } = useThemeContext()
	const fill = color.primary.base
	const stroke = color.primary.accent

	return <Home fill={fill} stroke={stroke} size={24} />
}

export const HomeLink = (): ReactElement => (
	<Link to={SiteConfig.siteInfo.rootUrl}>
		<HomeIcon />
	</Link>
)
