import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'

//----- Styling -----//

const Node = styled.h2`
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.regular};
	font-size: 1.5em;
`

//----- Component -----//

export const Heading2 = (
	props: JSX.IntrinsicElements['h2'] & ExtraProps
): JSX.Element => {
	const { node, ref, ...rest } = props

	return <Node {...rest} />
}
