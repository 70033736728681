import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor, Image_Contentful } from '~/components/atoms'

//----- Styling -----//

const Image = styled(Image_Contentful)`
	border: 2px solid ${(p) => p.theme.color.primary.base.contrast};
`

//----- Component -----//

export const IssueCover = (props: {
	imageUrl: string
	issueUrl: string
}): ReactElement => (
	<Anchor
		to={props.issueUrl}
		style={{ display: 'block' }}
		aria-label="View the Latest Issue"
	>
		<Image
			src={props.imageUrl}
			alt="Latest Issue Cover"
			maxWidth={174}
			maxHeight={227}
		/>
	</Anchor>
)
