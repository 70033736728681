import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor, Image_Contentful } from '~/components/atoms'
import type { BlogData } from '~/types/content'
import { getPath } from '~/util'

//----- Styling -----//

const Icon = styled(Image_Contentful)`
	display: inline-block;
	transform: translateY(8px);
	margin-right: 4px;
`

//----- Component -----//

export const Blog = (props: { blog?: BlogData }): ReactElement | null => {
	if (!props.blog) return null

	return (
		<span>
			{props.blog?.image.avatar && (
				<Icon
					src={props.blog.image.avatar.url}
					alt={`${props.blog.name} icon`}
					maxWidth={24}
					maxHeight={24}
					eager
				/>
			)}
			<Anchor to={getPath.blog(props.blog?.slug)}>{props.blog?.name}</Anchor>
		</span>
	)
}
