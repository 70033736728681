import { ReactElement } from 'react'
import styled from 'styled-components'

import { Image_Contentful } from '~/components/atoms'
import { SiteConfig } from '~/config'
import type { ImageData } from '~/types/content'

//----- Styling -----//

const CaptionWrapper = styled.div<{
	maxImageWidth?: number
	withLine?: boolean
}>`
	& > .lazyload-wrapper {
		max-width: ${(p) => p.maxImageWidth}px;
		margin: 0 auto;
	}

	${(p) =>
		p.withLine &&
		`
		border-bottom: 1px solid ${p.theme.color.gray[74]};
	`}
`

const Caption = styled.div`
	padding: 1em 0;
	line-height: 1.25;
	color: ${(p) => p.theme.color.gray[46]};
`

//----- Component -----//

export const CaptionedImage = (props: {
	image?: ImageData
	template?: string
	eager?: boolean
	preload?: boolean
	maxImageWidth?: number
	maxImageHeight?: number
	resizeMethod?: 'pad' | 'fill'
	withLine?: boolean
}): ReactElement | null => {
	if (!props.image || props.template === SiteConfig.article.template.gallery)
		return null

	return (
		<CaptionWrapper
			maxImageWidth={props.maxImageWidth || 660}
			withLine={props.withLine}
		>
			<Image_Contentful
				src={props.image.url}
				alt={props.image.title || props.image.description || 'Article image'}
				maxWidth={props.maxImageWidth || 660}
				maxHeight={props.maxImageHeight || 433}
				resizeMethod={props.resizeMethod}
				eager={props.eager}
				preload={props.preload}
			/>
			<Caption>{props.image.description}</Caption>
		</CaptionWrapper>
	)
}
