import { ReactElement } from 'react'
import { useRouter } from 'next/router'

import { useAppContext } from '~/contexts'

import { FormButton, ViewTitle } from '../../atoms'

//----- Component -----//

export const FailedView = (props: { tryAgain: () => void }): ReactElement => {
	const app = useAppContext()
	const router = useRouter()

	function handleContact() {
		app.setRegisterModalOpen(false)
		router.push('/contact-us')
	}

	return (
		<>
			<ViewTitle>Registration failed!</ViewTitle>
			<p>
				An error has occurred while attempting to process your registration.
			</p>
			<p>
				Please review your information and try again. If the issue continues,
				please contact us for additional support.
			</p>
			<FormButton onClick={props.tryAgain}>Try Again</FormButton>
			<FormButton onClick={handleContact} variant="contrast">
				Contact Us
			</FormButton>
		</>
	)
}
