import React from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import type { AuthorData, ReviewerData } from '~/types/content'
import { getPath } from '~/util'

//----- Styling -----//

const PersonWrapper = styled.span`
	font-style: italic;
`

//----- Util -----//

/**
 * Determines the prefix string to use based on person type & reviewer type
 */
function getPrefix(personType: string, reviewerType?: string) {
	if (personType === 'Author') return 'By '

	switch (reviewerType) {
		case 'Medical':
			return '; Medically Reviewed by '
		default:
			return '; Reviewed by '
	}
}

/**
 * Determine the correct slug to use based on person type
 */
function getPersonPath(personType: 'Author' | 'Reviewer', slug?: string) {
	if (personType === 'Author') return getPath.author(slug)
	if (personType === 'Reviewer') return getPath.reviewer(slug)

	return slug
}

//----- Component -----//

type BaseProps = { i: number; total: number }
type AuthorProps = BaseProps & { person: AuthorData; type: 'Author' }
type ReviewerProps = BaseProps & { person: ReviewerData; type: 'Reviewer' }
type PersonProps = AuthorProps | ReviewerProps

export const Person: React.FC<PersonProps> = ({ i, total, person, type }) => {
	if (!person) return null

	const prefix = getPrefix(type, type === 'Reviewer' ? person.type : undefined)

	const isOneOfMany = total > 2
	const isFirst = i === 0
	const isLast = i === total - 1

	return (
		<PersonWrapper>
			{isFirst && prefix}
			{isOneOfMany && !isFirst ? ', ' : ''}
			{isLast && !isFirst ? ' and ' : ''}
			<Anchor to={getPersonPath(type, person.slug)}>{person.name}</Anchor>
		</PersonWrapper>
	)
}
