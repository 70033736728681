import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import type { CategoryData } from '~/types/content'
import { getPath } from '~/util'

const StyledLink = styled(Anchor)<{ category?: string }>`
	display: inline-block;
	margin-top: 1.6em;
	padding: 0.9em 1.1em 0.8em;

	font-size: 0.625em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	letter-spacing: 0.1em;
	line-height: 1;

	text-transform: uppercase;
	color: ${(p) => p.theme.color.primary.base};
	background-color: ${(p) =>
		p.category && p.theme.color.category[p.category]
			? p.theme.color.category[p.category]
			: p.theme.color.category.default};
`

export const CategoryLink = (props: {
	category?: CategoryData
}): ReactElement => (
	<div>
		<StyledLink
			to={getPath.category(props.category?.slug)}
			category={props.category?.slug}
		>
			{props.category?.name}
		</StyledLink>
	</div>
)
