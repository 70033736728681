import React from 'react'
import Markdown from 'react-markdown'
import {
	Blockquote,
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	Heading6,
	HorizontalRule,
	Hyperlink,
	ListItem,
	OrderedList,
	Strong,
	UnorderedList
} from './atoms'

export const MarkdownText: React.FC<{ content: string }> = (props) => {
	return (
		<Markdown
			components={{
				blockquote: Blockquote,
				h1: Heading1,
				h2: Heading2,
				h3: Heading3,
				h4: Heading4,
				h5: Heading5,
				h6: Heading6,
				hr: HorizontalRule,
				a: Hyperlink,
				li: ListItem,
				ol: OrderedList,
				strong: Strong,
				ul: UnorderedList
			}}
		>
			{props.content}
		</Markdown>
	)
}
