import { NextPage } from 'next'
import { useEffect } from 'react'

import { PageTitle, StickySidebar, UserHydrator } from '~/components/atoms'
import { RichText } from '~/components/organisms'
import { ErrorPage } from '~/components/pages'
import { StandardTemplate } from '~/components/templates'
import { PageProvider } from '~/contexts'
import type { CustomPageProps } from '~/types/page'
import { Analytics, getPath } from '~/util'

import { NewsletterSignup } from './organisms'

export const CustomPage: NextPage<CustomPageProps> = (props) => {
	const { errorStatus, page, user, content } = props

	useEffect(() => {
		if (content?.type === 'page' && content?.page.slug)
			Analytics.getInstance()
				.setLoginStatus(user?.email, user?.isLoggedIn, user?.hasMagazineSub)
				.setCategory(null)
				.pageview(getPath.customPage(content.page.slug))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (errorStatus) return <ErrorPage errorStatus={errorStatus} />
	if (!content?.page || content?.type !== 'page')
		return <ErrorPage errorStatus={500} />

	return (
		<UserHydrator user={user}>
			<PageProvider pageConfig={page}>
				<StandardTemplate
					right={<StickySidebar>{null}</StickySidebar>}
					useContainer
				>
					<PageTitle>{content.page.title}</PageTitle>
					<RichText rootNode={content.page.body} />
					{content.page.template === 'Newsletter' && <NewsletterSignup />}
				</StandardTemplate>
			</PageProvider>
		</UserHydrator>
	)
}
