import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import { useAppContext, useUserContext } from '~/contexts'

//----- Styling -----//

const Prompts = styled.div`
	font-size: 0.85rem;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	letter-spacing: ${(p) => p.theme.font.sans.spacing.slim};
`

const PromptAnchor = styled(Anchor)`
	color: inherit;
	text-decoration: underline;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	transition: opacity 200ms ease-in-out;

	&:hover {
		opacity: 0.75;
	}
`

const PromptParagraph = styled.p`
	margin: 0;
	line-height: 1.5;
`

//----- Component -----//

export const ModalPrompts = (): ReactElement => {
	const app = useAppContext()
	const user = useUserContext()

	const { toggleAccessCodeModal, toggleLoginModal, toggleRegisterModal } = app

	return (
		<Prompts>
			{user.isLoggedIn ? (
				<>
					Enter an{' '}
					<PromptAnchor onClick={toggleAccessCodeModal}>
						Access Code
					</PromptAnchor>
				</>
			) : (
				<>
					<PromptParagraph>Already a subscriber?</PromptParagraph>
					<PromptParagraph>
						<PromptAnchor onClick={toggleRegisterModal}>Register</PromptAnchor>{' '}
						or <PromptAnchor onClick={toggleLoginModal}>Log In</PromptAnchor>
					</PromptParagraph>
				</>
			)}
		</Prompts>
	)
}
