import { ComponentProps, ReactElement } from 'react'

import { Input, InputCaption, ResultMessage } from '~/components/atoms'

//----- Component -----//

/**
 * Container of Input element which includes optional caption and message
 * @param input Takes all HTML input parameters as an object
 */
export const InputBlock = (props: {
	caption?: string
	message?: ComponentProps<typeof ResultMessage>
	input?: ComponentProps<typeof Input>
}): ReactElement => {
	const { input, caption, message } = props

	return (
		<label>
			{caption && <InputCaption>{caption}</InputCaption>}
			<Input {...input} />
			<ResultMessage {...message} />
		</label>
	)
}
