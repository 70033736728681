import React from 'react'
import { ExtraProps } from 'react-markdown'

//----- Component -----//

export const Heading6 = (
	props: JSX.IntrinsicElements['h6'] & ExtraProps
): JSX.Element => {
	const { children, node, ref, ...rest } = props

	return <h6 {...rest}>{children}</h6>
}
