import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { usePageContext } from '~/contexts'
import { Analytics } from '~/util'

//----- Styling -----//

const NavLinks = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
	justify-items: center;
	align-items: center;
	text-align: center;

	width: 100%;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		grid-template-columns: repeat(4, 1fr);
	}

	a {
		color: ${(p) => p.theme.color.primary.base};
		text-transform: uppercase;
	}
`

//----- Component -----//

export const Navigation = (): ReactElement => {
	const { path } = usePageContext()
	const { linkList } = SiteConfig.footerInfo

	const handleClick = (behavior?: string) => () => {
		switch (behavior) {
			case 'trackSubscribeClick':
				Analytics.getInstance().event({
					category: 'Paid Subscriber Click',
					action: 'Subscription Click',
					label: path
				})
				break
		}
	}

	return (
		<NavLinks>
			{linkList.map((link, i) => (
				<Anchor
					key={i}
					to={link.url}
					onClick={() => handleClick(link.behavior)}
				>
					{link.text}
				</Anchor>
			))}
		</NavLinks>
	)
}
