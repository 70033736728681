import { ReactElement } from 'react'

import { Carousel } from '~/components/molecules'
import type { IssueData } from '~/types/content'

import { IssueCard } from '../molecules'

//----- Component -----//

export const IssueCarousel = (props: {
	activeIssue: number
	issues: IssueData[]
}): ReactElement | null => {
	if (!props.issues || !props.issues.length) return null

	const carouselConfig = {
		responsive: [
			{ breakPoint: 0, cardsToShow: 1 },
			{ breakPoint: 580, cardsToShow: 2 },
			{ breakPoint: 826, cardsToShow: 3 },
			{ breakPoint: 1024, cardsToShow: 4 }
		]
	}

	return (
		<Carousel config={carouselConfig} activeCard={props.activeIssue}>
			{props.issues.map((issue, index) => (
				<IssueCard
					key={index}
					issue={issue}
					isActive={index === props.activeIssue}
				/>
			))}
		</Carousel>
	)
}
