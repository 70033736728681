import { ChangeEvent, ReactElement } from 'react'
import styled from 'styled-components'
import Image from 'next/image'

//----- Styling -----//

const Selection = styled.li`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 2em 0 1em;
	list-style: none;

	@media (min-width: 701px) and (max-width: 899px), (min-width: 1033px) {
		justify-content: space-between;
	}
`

const Info = styled.div`
	max-width: 390px;
	margin: 1em 1em 0;
	padding-left: 1.7em;
`

const Label = styled.label`
	position: relative;
	display: block;
`

const Input = styled.input`
	position: absolute;
	left: -1.7em;
	top: 50%;
	transform: translateY(-50%);
`

const MagazineName = styled.span`
	font-family: ${(p) => p.theme.font.title.family};
`

const Description = styled.p`
	margin-top: 0.5em;
`

//----- Component -----//

interface Newsletter {
	code: string
	name: string
	description: string
}

export const NewsletterSelection = (props: {
	newsletter: Newsletter
	onChange: (name: string, isSelected: boolean) => void
	selected?: boolean
}): ReactElement => {
	const onChange = (event: ChangeEvent<HTMLInputElement>) =>
		props.onChange(event.target.name, event.target.checked)

	return (
		<Selection>
			<div>
				<Image
					src={`/assets/newsletterLogos/${props.newsletter.code}.png`}
					alt={`${props.newsletter.name} Logo`}
				/>
			</div>
			<Info>
				<Label>
					<Input
						type="checkbox"
						name={props.newsletter.code}
						onChange={onChange}
						checked={props.selected}
					/>
					<MagazineName>{props.newsletter.name}</MagazineName>
				</Label>
				<Description>{props.newsletter.description}</Description>
			</Info>
		</Selection>
	)
}
