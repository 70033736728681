import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { PromoCard } from '~/components/molecules'
import type { PromoCardData } from '~/types/content'

//----- Styling -----//

const Area = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 512px;

	& > div {
		margin-bottom: 2em;
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		flex-direction: row;
		max-width: 100%;

		& > div {
			margin-bottom: 2em;
		}

		& > div + div {
			margin-left: 2em;
		}
	}
`

//----- Component -----//

export const PromoArea = (props: {
	cards?: PromoCardData[]
}): ReactElement | null => {
	if (!props.cards || !props.cards.length) return null

	return (
		<Area>
			{props.cards.map((card, i) => (
				<PromoCard key={i} card={card} />
			))}
		</Area>
	)
}
