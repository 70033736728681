import type { PageIndexItem } from '~/types/app'

/**
 * Determines the lowest heading level available
 */
export function getRootLevel(headings: PageIndexItem[]): number {
	let lowestLevel = 10

	headings.forEach((node) => {
		if (node.level < lowestLevel) lowestLevel = node.level
	})

	return lowestLevel
}
