import { ReactElement } from 'react'

import { BlockDescriptor, Container } from '~/components/atoms'
import { SubscribeTile, NewsletterTile } from '~/components/molecules'
import { ArticleGrid, ProductArea, TileBlock } from '~/components/organisms'
import type { ArticleSummaryData, ProductCardData } from '~/types/content'

export const ArticleBottom = (props: {
	articles?: ArticleSummaryData[]
	products?: ProductCardData[]
}): ReactElement => (
	<Container>
		<BlockDescriptor marginTop marginBottom>
			More From Discover
		</BlockDescriptor>
		<ArticleGrid articles={props.articles} wideThumbs />

		<ProductArea products={props.products} noFooter />

		<TileBlock>
			<NewsletterTile />
			<SubscribeTile />
		</TileBlock>
	</Container>
)
