import React, { useState, ReactElement } from 'react'
import styled from 'styled-components'

import { useScrollPosition } from '~/util'

import { Drawer, Logo, SiteNav, UserPanel } from './molecules'

//----- Configuration -----//

const MASTHEAD = {
	FULL: 85,
	COLLAPSED: 64
}

const HEADER_HEIGHT = {
	MOBILE: MASTHEAD.FULL,
	DESKTOP: MASTHEAD.FULL + (MASTHEAD.COLLAPSED - 9) + 1
}

//----- Styling -----//

const HeaderWrapper = styled.div`
	position: relative;
	height: ${HEADER_HEIGHT.MOBILE}px;

	font-size: 1rem;

	z-index: 1000;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		height: ${HEADER_HEIGHT.DESKTOP}px;
	}
`

const StickyHeader = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
`

const MainSection = styled.div.attrs<
	{ collapsed: boolean },
	{ bgColor: string; breakpoint: string }
>((props) => ({
	bgColor: props.theme.color.primary.base,
	breakpoint: props.theme.responsive.breakpoints.md
}))<{ collapsed: boolean }>`
	position: relative;

	display: grid;
	align-items: center;
	grid-gap: 10px;
	grid-template-columns: 1fr auto 1fr;

	padding: 0 32px;
	height: ${(p) => (p.collapsed ? MASTHEAD.COLLAPSED : MASTHEAD.FULL)}px;

	background: ${(p) => p.bgColor};
	border-bottom: 1px solid ${(p) => p.theme.color.primary.contrast};

	transition: height 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	z-index: 100;

	/* Set the right column to right-align its content */
	& > div:last-child {
		display: flex;
		justify-content: flex-end;
	}
`

//----- Component -----//

export const Header = (): ReactElement => {
	const [collapsed, setCollapsed] = useState(false)

	// Track scroll position to determine collapse state
	let lastScrollPosition = 0

	// To ensure the user has a 'home' space from which they can always access the
	// full header, we will provide an offset range where header collapse is disabled
	const scrollOffset = 200

	// For a more fluid UX, we'll add a little buffering to scroll directions
	// so that the header doesn't toggle until the user has scrolled a bit
	const scrollBuffer = 150

	// Sets 'isCollapsed' if the user is past the minimum offset and is scrolling down
	function onScroll() {
		const scrollPosition = window.scrollY
		const isPastOffset = scrollPosition > scrollOffset
		const isScrollingDown = scrollPosition > lastScrollPosition

		// User is at the top of the page; never collapse
		if (!isPastOffset) {
			setCollapsed(false)
			lastScrollPosition = scrollPosition
			return
		}

		// User has scrolled down; always collapse immediately
		if (scrollPosition - lastScrollPosition > 0) {
			setCollapsed(true)
			lastScrollPosition = scrollPosition
			return
		}

		// User has scrolled up; uncollapse only if they scroll back more than the buffer
		if (Math.abs(scrollPosition - lastScrollPosition) > scrollBuffer) {
			setCollapsed(isScrollingDown)
			lastScrollPosition = scrollPosition
		}
	}

	useScrollPosition({
		effect: onScroll,
		throttle: 100
	})

	return (
		<HeaderWrapper>
			<StickyHeader>
				<MainSection collapsed={collapsed}>
					<div>
						<SiteNav />
					</div>
					<div>
						<Logo collapsed={collapsed} />
					</div>
					<div>
						<UserPanel isCollapsed={collapsed} />
					</div>
				</MainSection>
				<Drawer collapsed={collapsed} />
			</StickyHeader>
		</HeaderWrapper>
	)
}
