import { ReactElement } from 'react'
import styled from 'styled-components'

import { AnchorOffset } from '~/components/atoms'
import { ContentAsReact, getHeadingData } from '../util'
import type { RichTextContent } from '~/types/content'

//----- Styling -----//

const H2 = styled.h2`
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.regular};
	font-size: 1.5em;
`

//----- Component -----//

export const Heading2 = (props: RichTextContent): ReactElement => (
	<AnchorOffset id={getHeadingData(props)?.slug}>
		<H2>
			<ContentAsReact content={props.content} />
		</H2>
	</AnchorOffset>
)
