import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const Node = styled.blockquote`
	margin: 0;
	padding: 0.4em 0;

	font-size: 1.25em;
	text-align: center;
`

//----- Component -----//

export const Blockquote = (props: RichTextContent): ReactElement => {
	return (
		<Node>
			<ContentAsReact content={props.content} />
		</Node>
	)
}
