import {
	ComponentProps,
	FormEvent,
	ReactElement,
	useEffect,
	useState
} from 'react'
import styled from 'styled-components'

import { Button_Solid, Input, ResultMessage } from '~/components/atoms'
import { useUserContext } from '~/contexts'
import { ApiClient, validate } from '~/util'

//----- Styling -----//

const Form = styled.form`
	width: 100%;
	font-size: 0.75em;
`

const FormFields = styled.div`
	display: flex;
	height: auto;
	width: 100%;
`

//----- Component -----//

export const NewsletterForm = (props: {
	signupSource: string
	subscriptions?: string[]
	invertedMessages?: boolean
}): ReactElement => {
	const user = useUserContext()
	const [userEmail, setUserEmail] = useState(user.email || '')
	const [message, setMessage] = useState<ComponentProps<typeof ResultMessage>>({
		variant: 'empty'
	})

	useEffect(() => {
		setUserEmail(user.email || '')
	}, [user.email])

	const handleSubmit = async (
		event: FormEvent<HTMLFormElement>
	): Promise<void> => {
		// Don't let the form actually submit
		event.preventDefault()

		// Validate email input
		if (!validate.email(userEmail)) {
			setMessage({
				text: 'Please provide a valid email address',
				variant: 'warn'
			})
			return
		}

		const success = await ApiClient.newsletterSignup(
			userEmail,
			props.subscriptions || ['DSC'],
			props.signupSource
		)

		setMessage(
			success
				? { text: 'You have been subscribed!', variant: 'success' }
				: { text: 'Unable to subscribe; please try again', variant: 'fail' }
		)
	}

	return (
		<Form onSubmit={handleSubmit}>
			<FormFields>
				<Input
					aria-label="Email Address"
					autoComplete="email"
					defaultValue={user.email}
					placeholder="Email Address"
					onChange={(e) => setUserEmail(e.target.value)}
					inline
				/>
				<Button_Solid aria-label="Sign Up for Newsletter" type="submit">
					Sign Up
				</Button_Solid>
			</FormFields>
			<ResultMessage {...message} inverted={props.invertedMessages} />
		</Form>
	)
}
