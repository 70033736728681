import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const UL = styled.ul`
	margin: 1em 0;
`

//----- Component -----//

export const UnorderedList = (props: RichTextContent): ReactElement => (
	<UL>
		<ContentAsReact content={props.content} />
	</UL>
)
