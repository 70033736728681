import { ReactElement } from 'react'

import { HorizontalRule, ModalTitle } from '~/components/atoms'
import { Modal } from '~/components/molecules'
import { useAppContext } from '~/contexts'

import { AccessCodeForm, PartnerList } from './molecules'

//----- Consts -----//

const partnerImages = [
	{
		src: '/assets/digitalPartners/google.png',
		alt: 'Google Play'
	},
	{
		src: '/assets/digitalPartners/apple.png',
		alt: 'Apple App Store'
	},
	{
		src: '/assets/digitalPartners/kindle.png',
		alt: 'Amazon Kindle'
	},
	{
		src: '/assets/digitalPartners/nook.png',
		alt: 'Nook'
	},
	{
		src: '/assets/digitalPartners/zinio.png',
		alt: 'Zinio'
	}
]

//----- Component -----//

export const AccessCodeModal = (): ReactElement => {
	const app = useAppContext()

	return (
		<Modal isOpen={app.accessCodeModalIsOpen}>
			<ModalTitle>Website Access Code</ModalTitle>
			<p>Enter your access code into the form field below.</p>
			<AccessCodeForm />
			<HorizontalRule />
			<p>
				If you are a Zinio, Nook, Kindle, Apple, or Google Play subscriber, you
				can enter your website access code to gain subscriber access. Your
				website access code is located in the upper right corner of the Table of
				Contents page of your digital edition.
			</p>
			<PartnerList images={partnerImages} />
		</Modal>
	)
}
