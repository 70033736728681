import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { SidebarTitle } from '~/components/atoms'
import { ArticleCard_Wide } from '~/components/molecules'
import type { ArticleSummaryData } from '~/types/content'

//----- Styling -----//

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 300px;

	& > a:first-of-type {
		padding-top: 8px;
	}

	& > a + a {
		padding-top: 16px;
	}
`

//----- Component -----//

interface ArticleSummaryProps {
	title: string
	articles: ArticleSummaryData[]
}

export function ArticleSummary_Vertical(
	props: ArticleSummaryProps
): ReactElement {
	return (
		<ContentWrapper>
			<SidebarTitle>{props.title}</SidebarTitle>
			{props.articles?.map((article, i) => (
				<ArticleCard_Wide key={i} article={article} />
			))}
		</ContentWrapper>
	)
}
