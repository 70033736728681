import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor_Internal } from '~/components/atoms'
import type { ArticleSummaryData } from '~/types/content'
import { getPath } from '~/util'

import { CategoryLink } from './atoms'

//----- Styling -----//

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const ArticleAnchor = styled(Anchor_Internal)`
	margin: 0.75em 0 0;
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.bold};
`

//----- Component -----//

export const ArticleCard_TextOnly = (props: {
	article?: ArticleSummaryData
}): ReactElement | null => {
	const { article } = props
	if (!article || !article.slug) return null

	const categorySlug = article.refs?.category?.slug
	const articlePath = getPath.article(article.slug, categorySlug)

	return (
		<CardContainer>
			<div>
				<CategoryLink
					category={article.refs?.category}
					template={article.meta?.template}
				/>
			</div>
			<ArticleAnchor to={articlePath}>{article.title}</ArticleAnchor>
		</CardContainer>
	)
}
