import { ReactElement } from 'react'
import styled from 'styled-components'

import { getVideoEmbedUrl } from '~/util'

//----- Styling -----//

/*
 * By placing an absolutely-positioned Player inside a relatively-positioned
 * Wrapper, we can improve how the embedded iframe behaves responsively
 */
const StyledWrapper = styled.div`
	position: relative;
	margin: 1em 0;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
`

const StyledPlayer = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
`

//----- Component -----//

export const VideoPlayer = (props: { url?: string }): ReactElement | null => {
	const url = getVideoEmbedUrl(props.url)

	if (!url) return null

	return (
		<StyledWrapper>
			<StyledPlayer
				src={url}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</StyledWrapper>
	)
}
