import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import type { TagData } from '~/types/content'
import { getPath } from '~/util'

//----- Styling -----//

const StyledTag = styled.li`
	display: inline-block;
	margin: 0 0.5em 0.5em 0;
	padding: 0.25em 0.5em;

	font-weight: ${(p) => p.theme.font.sans.weight.semiBold};
	text-transform: lowercase;
	background: ${(p) => p.theme.color.gray[94]};

	& > a {
		color: ${(p) => p.theme.color.gray[13]};
	}
`

//----- Component -----//

export const Tag = (props: { tag: TagData }): ReactElement => {
	const path = getPath.tag(props.tag.slug)
	return (
		<StyledTag>
			<Anchor to={path}>{props.tag.name}</Anchor>
		</StyledTag>
	)
}
