import { NextPage } from 'next'
import { useEffect, useRef, useState } from 'react'

import { InstareadPlayer, UserHydrator } from '~/components/atoms'
import { CaptionedImage, SocialShares, TagList } from '~/components/molecules'
import { MeteringBanner, PaywallPrompt, RichText } from '~/components/organisms'
import { StandardTemplate } from '~/components/templates'
import { SiteConfig } from '~/config'
import { PageProvider, PaywallProvider } from '~/contexts'
import { Category } from '~/types/category'
import type { ArticlePageProps } from '~/types/page'
import {
	BlogHerAds,
	getPath,
	getShouldBeDesktop,
	useScrollPosition
} from '~/util'

import { ArticleMeta, NewsletterCallout } from './molecules'
import { ArticleBottom, ArticleRight, ArticleTop } from './organisms'
import { sendAnalyticsEvent } from './util'
import {
	checkScrollEvent,
	getNewScrollThresholds
} from './util/checkScrollEvent'

export const ArticlePage: NextPage<ArticlePageProps> = (props) => {
	const { page, permission, content, user } = props
	const article = content?.article

	const [isDesktop, setIsDesktop] = useState(false)
	const richTextRef = useRef<HTMLDivElement>(null)

	let thresholds = getNewScrollThresholds()

	// Use fallback values if category is not provided
	const category = {
		name: article?.refs.category?.name || 'uncategorized',
		slug: article?.refs.category?.slug || 'uncategorized'
	}

	const articlePath = getPath.article(article?.slug || '', category.slug)
	const articleUrl = `${SiteConfig.siteInfo.rootUrl}${articlePath}`

	function handlePageResize() {
		const shouldBeDesktop = getShouldBeDesktop()
		setIsDesktop(shouldBeDesktop)
	}

	useEffect(() => {
		sendAnalyticsEvent({
			categoryName: category.name,
			url: articleUrl,
			user,
			permission
		})

		BlogHerAds.initialize('article')

		window.addEventListener('resize', handlePageResize)
		handlePageResize()

		return () => window.removeEventListener('resize', handlePageResize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// On scroll, trigger scroll analytics events based on thresholds
	useScrollPosition({
		effect: () => {
			thresholds = checkScrollEvent(
				articlePath,
				richTextRef.current,
				thresholds
			)
		},
		throttle: 250,
		dependencies: [articlePath]
	})

	if (!article) return null

	// TODO: Move Providers & Hydrator into StandardTemplate
	return (
		<UserHydrator user={user}>
			<PageProvider pageConfig={page}>
				<PaywallProvider paywallConfig={permission}>
					<StandardTemplate
						top={
							<ArticleTop
								articleBody={article.body}
								authors={article.refs.authors}
								blog={article.refs.blog}
								category={article.refs.category}
								isDesktop={isDesktop}
								indexVisibliity={article.meta.contentIndex}
								modifiedDate={article.meta.modifiedDate}
								publicationDate={article.meta.publicationDate}
								reviewers={article.refs.reviewers}
								subtitle={article.subtitle}
								title={article.title}
							/>
						}
						right={
							isDesktop && (
								<ArticleRight
									articleBody={article.body}
									articles={content?.related}
									indexVisibility={article.meta.contentIndex}
								/>
							)
						}
						bottom={
							<ArticleBottom
								articles={content?.more}
								products={content?.products}
							/>
						}
						hasSkimlinks={article?.meta.template === 'Skimlinks'}
						useContainer
					>
						<ArticleMeta article={article} url={articleUrl} />
						<SocialShares url={articleUrl} />
						<CaptionedImage
							image={article.image}
							template={article.meta.template}
							eager
							preload
						/>
						<NewsletterCallout />
						<InstareadPlayer />
						<RichText
							contentRef={richTextRef}
							rootNode={article.body}
							template={article.meta.template}
							category={category.slug as Category}
						/>
						<TagList tags={article.refs.tags} />

						<MeteringBanner />
						<PaywallPrompt />
					</StandardTemplate>
				</PaywallProvider>
			</PageProvider>
		</UserHydrator>
	)
}
