import { ReactElement } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { useAppContext, usePageContext, useUserContext } from '~/contexts'

import { FormButton, ViewTitle } from '../../atoms'

//----- Styling -----//

const UserEmail = styled.p`
	text-align: center;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

export const SuccessView = (): ReactElement => {
	const app = useAppContext()
	const page = usePageContext()
	const user = useUserContext()
	const router = useRouter()

	function handleContinue() {
		app.setRegisterModalOpen(false)
		router.replace(page.path || '/')
	}

	return (
		<>
			<ViewTitle>Thank you!</ViewTitle>
			<div>
				<p>You are now registered with the following email:</p>
				<UserEmail>{user.email}</UserEmail>
				<p>
					You can use your email address and newly created password to log in to
					the Discover website.
				</p>
			</div>
			<FormButton onClick={handleContinue}>Continue</FormButton>
		</>
	)
}
