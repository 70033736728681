import { ReactElement, useEffect } from 'react'
import styled from 'styled-components'

import { LoadingSpinner_Ring } from '~/components/atoms'
import { useUserContext } from '~/contexts'
import { ApiClient } from '~/util'

//----- Styling -----//

const LoadingArea = styled.div`
	padding: 3em 0 1.5em;
	text-align: center;
`

//----- Component -----//

export const LoadingView = (props: {
	showNext: (result: 'success' | 'rejected' | 'failed') => void
}): ReactElement => {
	const user = useUserContext()

	useEffect(() => {
		const { email, password, firstName, lastName, newsletterSelections } = user

		const register = async () => {
			const result = await ApiClient.register({
				email: email || '',
				pass: password || '',
				firstName: firstName || '',
				lastName: lastName || '',
				newsletters: newsletterSelections
			})

			if (result === true) {
				const loginResult = await ApiClient.login(email || '', password || '')
				// Must not be a status code
				user.hydrate({ isLoggedIn: loginResult === true, password: '' }, true)
				props.showNext('success')
			} else {
				user.setPassword('')
				props.showNext(result === 400 ? 'rejected' : 'failed')
			}
		}

		register()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<LoadingArea>
			<LoadingSpinner_Ring size={64} />
		</LoadingArea>
	)
}
