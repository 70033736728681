import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor_Internal, Image_Contentful } from '~/components/atoms'
import type { ArticleSummaryData } from '~/types/content'
import { getPath } from '~/util'

import { CategoryLink, GalleryTag, SponsoredTag } from './atoms'
import { getImageUrl } from './util'

//----- Styling -----//

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0.5em auto;
	max-width: 308px;
`

const ArticleAnchor = styled(Anchor_Internal)`
	margin: 0.75em 0 0;
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.bold};
`

//----- Component -----//

export const ArticleCard = (props: {
	article?: ArticleSummaryData
}): ReactElement | null => {
	const { article } = props
	if (!article || (!article.slug && !article.url)) return null

	const categorySlug = article.refs.category?.slug

	const articlePath = article.url || getPath.article(article.slug, categorySlug)
	const imageUrl = getImageUrl('lg', article.image?.url, categorySlug)

	return (
		<CardContainer>
			<div>
				<Anchor_Internal
					to={articlePath}
					style={{ position: 'relative' }}
					aria-label={article.title || 'Article'}
				>
					<Image_Contentful
						src={imageUrl}
						alt={article.title || 'Article'}
						maxWidth={308}
						maxHeight={205}
					/>
					<GalleryTag template={article.meta.template} />
					<SponsoredTag template={article.meta.template} />
				</Anchor_Internal>
			</div>
			<div>
				<CategoryLink
					category={article.refs.category}
					template={article.meta.template}
				/>
			</div>
			<ArticleAnchor to={articlePath}>{article.title}</ArticleAnchor>
		</CardContainer>
	)
}
