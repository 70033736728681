import React from 'react'
import { RichTextContent } from '~/types/content'

import { IndexList } from './atoms'
import { getArticleHeadings } from './util'
import { SidebarTitle } from '~/components/atoms'

export const ArticleIndex: React.FC<{
	articleBody?: string | RichTextContent
	visibility?: string // 'None' | 'Top-Level Only' | 'Full'
}> = ({ articleBody, visibility }) => {
	if (
		typeof articleBody === 'string' ||
		!articleBody?.content?.length ||
		!visibility ||
		visibility === 'None'
	)
		return null

	const headings = getArticleHeadings(articleBody.content)

	return (
		<>
			<SidebarTitle>In This Article</SidebarTitle>
			<IndexList headings={headings} includeChildren={visibility === 'Full'} />
		</>
	)
}
