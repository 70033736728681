import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

import { ReactStyledCarousel } from './_ReactStyledCarousel'

import { NavIcon } from './atoms'

//----- Styling -----//

const NavIconWrap = styled.button<{
	disabled?: boolean
	back?: boolean
	fixed?: boolean
}>`
	${(p) => (p.disabled ? 'display: none;' : '')}
	position: absolute;
	top: ${(p) => (p.fixed ? '200px' : '50%')};
	transform: translateY(-50%);
	${(p) => (p.back ? 'left: 8px;' : 'right: 8px;')}

	padding: 0;

	background: transparent;
	border: 0;
	outline: none;
	cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`

//----- Configuration -----//

interface CarouselConfig {
	LeftArrow?: ReactElement
	RightArrow?: ReactElement
	Dot?: ReactElement
	showArrows?: boolean
	showDots?: boolean
	cardsToShow?: number
	afterSlide?: () => null
	beforeSlide?: () => null
	infinite?: boolean
	responsive?: {
		breakPoint: number
		cardsToShow: number
	}[]
	autoSlide?: number | boolean
	pauseOnMouseOver?: boolean
	padding?: string | number
	margin?: string | number
	hideArrowsOnNoSlides?: boolean
	DotsWrapper?: () => null
	initialCard?: number
}

const defaults: CarouselConfig = {
	// Behavior
	showDots: false,
	infinite: false,
	autoSlide: false,
	hideArrowsOnNoSlides: true,
	DotsWrapper: () => null, // TODO: Necessary to address a bug in react-styled-carousel; Should be removed when bug is fixed
	// Responsive Layout
	responsive: [
		{
			breakPoint: 0,
			cardsToShow: 1
		},
		{
			breakPoint: 576,
			cardsToShow: 2
		},
		{
			breakPoint: 768,
			cardsToShow: 4
		}
	]
}

//----- Component -----//

interface CarouselProps {
	activeCard?: number
	fixedArrows?: boolean
	className?: string
	children: ReactNode
	config?: CarouselConfig
}

export const Carousel = (props: CarouselProps): ReactElement => {
	const { activeCard = 0, children, className, config = {} } = props

	const carouselProps: CarouselConfig = {
		LeftArrow: (
			<NavIconWrap back fixed={props.fixedArrows}>
				<NavIcon gallery back />
			</NavIconWrap>
		),
		RightArrow: (
			<NavIconWrap fixed={props.fixedArrows}>
				<NavIcon gallery />
			</NavIconWrap>
		),
		...defaults,
		...config
	}

	return (
		<ReactStyledCarousel
			{...carouselProps}
			initialCard={activeCard}
			className={className}
		>
			{children}
		</ReactStyledCarousel>
	)
}
