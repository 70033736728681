import styled from 'styled-components'

const DotsWrapper = styled.ul`
	display: block;
	list-style: none;
	text-align: center;
	padding: 0px;
	margin: 0px;
`

export default DotsWrapper
