import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor, Image_Contentful } from '~/components/atoms'
import type { PromoCardData } from '~/types/content'

//----- Styling -----//

const Card = styled.div`
	flex: 1 1 50%;
	padding: 1em 2em;

	border-top: 14px solid black;

	@media (min-width: 576px) {
		border-left: 1px solid ${(p) => p.theme.color.gray[90]};
	}
`

const CardLink = styled(Anchor)`
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
`

const CardTitle = styled.h3`
	color: ${(p) => p.theme.color.gray[26]};
	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	letter-spacing: ${(p) => p.theme.font.sans.spacing.wide};
	text-transform: uppercase;

	@media (min-width: 576px) {
		font-size: 0.875em;
	}
`

const CardImage = styled(Image_Contentful)`
	display: block;
	margin: 0 auto;
`

const CardText = styled.p`
	font-family: ${(p) => p.theme.font.title.family};
	font-size: 1.125em;
	line-height: 1.25;

	@media (min-width: 576px) {
		font-size: 1.25em;
	}

	&:hover,
	&:active,
	&:focus {
		text-decoration: underline;
	}
`

//----- Component -----//

export const PromoCard = (props: {
	card: PromoCardData
}): ReactElement | null => {
	if (!props.card) return null

	return (
		<Card>
			<CardLink to={props.card.link.target}>
				<CardTitle>{props.card.title}</CardTitle>
				<CardImage
					src={props.card.image?.url || ''}
					alt={props.card.image?.title || 'Promotional Image'}
					maxWidth={420}
					maxHeight={230}
				/>
				<CardText>{props.card.link.text}</CardText>
			</CardLink>
		</Card>
	)
}
