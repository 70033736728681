import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const OL = styled.ol`
	margin: 1em 0;
`

//----- Component -----//

export const OrderedList = (props: RichTextContent): ReactElement => (
	<OL>
		<ContentAsReact content={props.content} />
	</OL>
)
