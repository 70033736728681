import React from 'react'
import { NestedHeading } from '../types'
import { IndexList } from './IndexList'
import styled from 'styled-components'
import { Anchor } from '~/components/atoms'

//----- Styling -----//

const Item = styled.li`
	padding: 0.25em 0;
`

//----- Component -----//

export const IndexNode: React.FC<{
	heading: NestedHeading
	includeChildren?: boolean
}> = (props) => {
	return (
		<Item>
			<Anchor to={`#${props.heading.slug}`}>{props.heading.label}</Anchor>
			{props.includeChildren && (
				<IndexList headings={props.heading.children} includeChildren indent />
			)}
		</Item>
	)
}
