import { ReactElement } from 'react'

import { VideoPlayer } from '~/components/molecules'
import { Gallery } from '~/components/organisms'
import {
	EmbeddableGalleryData,
	EmbeddableVideoData,
	RichTextContent
} from '~/types/content'

//----- Component -----//

export const EmbeddedEntry = (props: RichTextContent): ReactElement | null => {
	if (props.data.target?.sys.type !== 'Entry' || !props.data.target) return null

	const embedType = props.data.target.sys.contentType?.sys.id

	let fields

	switch (embedType) {
		case 'embeddableVideo':
			fields = props.data.target.fields as EmbeddableVideoData
			return <VideoPlayer url={fields.videoUrl} />

		case 'embeddableGallery':
			fields = props.data.target.fields as EmbeddableGalleryData
			return <Gallery cards={fields.cards} />
	}

	return null
}
