import React from 'react'
import type { BreadcrumbList, WithContext } from 'schema-dts'

import type { ArticleData } from '~/types/content'

import { SiteConfig } from '~/config'

import Head from 'next/head'

//----- Component -----//

const { rootUrl } = SiteConfig.siteInfo

/* Structured Data / CreativeWork schema */
export const BreadcrumbData: React.FC<{ article: ArticleData }> = ({
	article
}) => {
	const breadcrumbs = [
		{ name: 'Home', url: rootUrl },
		{
			name: article.refs.category?.name,
			url: `${rootUrl}/${article.refs.category?.slug}`
		},
		{
			name: article.title,
			url: `${rootUrl}/${article.refs.category?.slug}/${article.slug}`
		}
	]

	const schema: WithContext<BreadcrumbList> = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: breadcrumbs.map((crumb, i) => ({
			'@type': 'ListItem',
			position: i + 1,
			item: { '@id': crumb.url, name: crumb.name }
		}))
	}

	return (
		<Head>
			<script
				key="breadcrumb-data"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
			/>
		</Head>
	)
}
