import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'

//----- Styling -----//

const Node = styled.li`
	& + li {
		margin-top: 0.25em;
	}

	p:first-child {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}
`

//----- Component -----//

export const ListItem = (
	props: JSX.IntrinsicElements['li'] & ExtraProps
): JSX.Element => {
	const { node, ref, ...rest } = props

	return <Node {...rest} />
}
