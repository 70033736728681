import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const LI = styled.li`
	& + li {
		margin-top: 0.25em;
	}

	p:first-child {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}
`

//----- Component -----//

export const ListItem = (props: RichTextContent): ReactElement => (
	<LI>
		<ContentAsReact content={props.content} />
	</LI>
)
