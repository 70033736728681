import React from 'react'
import Head from 'next/head'

//----- Component -----//

export const MetaTags: React.FC<{ url: string }> = ({ url }) => (
	<Head>
		<link rel="canonical" href={url} />
	</Head>
)
