import type { NestedHeading } from '../types'

/**
 * Finds all child nodes for the given node item
 * @param parentNode Node to find children for
 * @param headings Full list of all heading items
 * @returns Heading node with all associated children
 */
export function getChildNodes(
	parentNode: NestedHeading,
	headings: NestedHeading[]
): NestedHeading[] {
	const children: NestedHeading[] = []

	for (let i = 0; i < headings.length; i++) {
		const node = headings[i]

		// Ignore entries prior to this one
		if (node.order <= parentNode.order) continue
		// Stop parsing entries for sibling/parent nodes
		if (node.level <= parentNode.level) break
		// Ignore grandchildren and below
		if (node.level > parentNode.level + 1) continue

		node.children = getChildNodes(node, headings)

		children.push(node)
	}

	return children
}
