import React from 'react'
import styled from 'styled-components'

import type { NestedHeading } from '../types'
import { IndexNode } from './IndexNode'

//----- Styling -----//

const List = styled.ul<{ indent?: boolean }>`
	margin: 0;
	padding: 0 0 0 ${(p) => (p.indent ? '1em' : '0')};
	list-style: none;
`

//----- Component -----//

export const IndexList: React.FC<{
	headings?: NestedHeading[]
	indent?: boolean
	includeChildren?: boolean
}> = (props) => {
	if (!props.headings?.length) return null

	return (
		<List indent={props.indent}>
			{props.headings.map((heading, i) => (
				<IndexNode
					heading={heading}
					includeChildren={props.includeChildren}
					key={i}
				/>
			))}{' '}
		</List>
	)
}
