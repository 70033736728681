import { ReactElement } from 'react'

import {
	HorizontalRule,
	SidebarContent,
	StickySidebar
} from '~/components/atoms'
import { ArticleIndex, ArticleSummary_Vertical } from '~/components/organisms'
import type { ArticleSummaryData, RichTextContent } from '~/types/content'

export const ArticleRight = (props: {
	articleBody?: string | RichTextContent
	articles?: ArticleSummaryData[]
	indexVisibility?: string
}): ReactElement => (
	<StickySidebar>
		<div id="articlePageSideDesktop"></div>
		<div id="articlePageSideMobile"></div>
		{props.indexVisibility && props.indexVisibility !== 'None' && (
			<>
				<SidebarContent>
					<ArticleIndex
						articleBody={props.articleBody}
						visibility={props.indexVisibility}
					/>
				</SidebarContent>
				<SidebarContent>
					<HorizontalRule />
				</SidebarContent>
			</>
		)}
		<SidebarContent>
			<ArticleSummary_Vertical
				title={'Related Content'}
				articles={props.articles || []}
			/>
		</SidebarContent>
	</StickySidebar>
)
