import styled from 'styled-components'

//----- Styling -----//

export const PrivacyNote = styled.div`
	font-size: 0.9em;

	& > a {
		font-weight: ${(p) => p.theme.font.sans.weight.bold};
		color: ${(p) => p.theme.color.gray[13]};
	}
`
