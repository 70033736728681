import { getHeadingData } from '../../RichText/util'
import { getNestedHeadings } from './getNestedHeadings'

import type { PageIndexItem } from '~/types/app'
import type { RichTextContent } from '~/types/content'
import type { NestedHeading } from '../types'

/**
 * Converts the provided RichText structure into a list of
 * nested article heading items.
 */
export function getArticleHeadings(
	content: RichTextContent[]
): NestedHeading[] {
	// Select all heading nodes and convert to PageIndexItem format
	const headings = content.reduce<PageIndexItem[]>((acc, node) => {
		const headingData = getHeadingData(node)
		if (headingData) acc.push(headingData)

		return acc
	}, [])

	return getNestedHeadings(headings)
}
