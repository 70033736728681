import type { UserStateData } from '~/contexts'
import { SiteConfig } from '~/config'
import type { PagePermission } from '~/types/app'
import { Analytics } from '~/util'

/**
 * Sends a Page View event to Google Analytics.
 *
 * Also sends a meter reduction event if applicable.
 */
export function sendAnalyticsEvent(options: {
	categoryName: string
	url: string
	user: UserStateData | null
	permission: PagePermission
}): void {
	const { categoryName, url, user, permission } = options
	const { meterHasReduced, viewsRemaining } = permission
	const eventConfig = SiteConfig.analytics.EVENT.METER_LIMIT_REDUCED

	// Remove the domain from the path, if present
	const pagePath = url.startsWith('http')
		? url.split('/').slice(3).join('/')
		: url

	const analytics = Analytics.getInstance()
		.setLoginStatus(user?.email, user?.isLoggedIn, user?.hasMagazineSub)
		.setCategory(categoryName)
		.pageview(`/${pagePath}`)

	if (meterHasReduced) {
		analytics.event({
			...eventConfig,
			action: viewsRemaining.toString(),
			label: url,
			nonInteraction: true
		})
	}
}
