import React from 'react'
import { ExtraProps } from 'react-markdown'

//----- Component -----//

export const Heading4 = (
	props: JSX.IntrinsicElements['h4'] & ExtraProps
): JSX.Element => {
	const { children, node, ref, ...rest } = props

	return <h4 {...rest}>{children}</h4>
}
