import styled from 'styled-components'

const SliderTrack = styled.div`
	width: auto;
	position: relative;
	height: auto;
	width: 100%;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: top;
`

export default SliderTrack
