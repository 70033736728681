import { ReactElement } from 'react'

import { Container } from '~/components/atoms'
import { SubscribeTile, NewsletterTile } from '~/components/molecules'
import { ProductArea, TileBlock } from '~/components/organisms'
import type { ProductCardData } from '~/types/content'

//----- Component -----//

export const MagazineBottom = (props: {
	products: ProductCardData[]
}): ReactElement => (
	<Container>
		<ProductArea products={props.products} noFooter />
		<TileBlock>
			<NewsletterTile />
			<SubscribeTile />
		</TileBlock>
	</Container>
)
