import styled from 'styled-components'
import { transparentize as fade } from 'polished'

//----- Styling -----//

const Label = styled.div`
	position: absolute;
	top: 10%;
	left: 0;
	padding: 0.5em 1em;

	font-size: 0.85em;
	font-weight: bold;
	background: ${(p) => fade(0.1, p.theme.color.gray[38])};
	color: white;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.sm}) {
		left: 1.2em;
	}
`

//----- Component -----//

export const PageLabel: React.FC<{
	currentPage: number
	totalPages: number
}> = ({ currentPage, totalPages }) => (
	<Label>
		{currentPage} / {totalPages}
	</Label>
)
