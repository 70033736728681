import type { PageIndexItem } from '~/types/app'
import type { RichTextContent, RichTextNodeType } from '~/types/content'
import { getStringAsSlug } from '~/util'

const allowedNodeTypes: RichTextNodeType[] = [
	'heading-1',
	'heading-2',
	'heading-3',
	'heading-4',
	'heading-5',
	'heading-6'
]

/**
 * Validates the provided node and extracts the heading data if valid
 */
export function getHeadingData(node?: RichTextContent): PageIndexItem | null {
	// Invalid nodes will return null data
	if (
		!node?.nodeType ||
		!allowedNodeTypes.includes(node.nodeType) ||
		!node?.content?.[0].value
	) {
		return null
	}

	const textContent = node.content[0].value

	return {
		level: parseInt(node.nodeType.split('-')[1]),
		slug: getStringAsSlug(textContent),
		label: textContent
	}
}
