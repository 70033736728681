import { ReactElement } from 'react'
import styled from 'styled-components'

import { Image_Contentful } from '~/components/atoms'
import { RichText } from '~/components/organisms'
import type { GalleryCardData } from '~/types/content'

//----- Styling -----//

const Card = styled.div`
	padding: 0 3em;
`

const Title = styled.div`
	font-size: 0.72em;
	line-height: 1.5em;
	color: ${(p) => p.theme.color.gray[46]};
`

//----- Component -----//

export const GalleryCard = (props: { card: GalleryCardData }): ReactElement => {
	const { card } = props

	return (
		<Card>
			{card.image && (
				<Image_Contentful
					src={card.image.url}
					alt={card.image.title || 'Gallery Image'}
					maxWidth={562}
				/>
			)}
			{card.credits && <Title>Photo Credits: {card.credits}</Title>}
			<RichText rootNode={card.caption} />
		</Card>
	)
}
