import { ReviewerData } from '~/types/content'

const NO_TYPE = 'no-reviewer-type'

/**
 * Split the provided reviewers array into multiple arrays based on the reviewer
 * 'type' property.
 *
 * Arrays will be ordered alphabetically by type name.
 */
export function getReviewersByType(
	reviewers?: ReviewerData[]
): ReviewerData[][] {
	if (!reviewers) return []

	// Place reviewers in a new object
	// - The property is the type name
	// - The value is the reviewer
	const grouped = reviewers.reduce(
		(result, item) => {
			const key = item.type || NO_TYPE
			const existing = result[key] || []

			return {
				...result,
				[key]: [...existing, item]
			}
		},
		{} as Record<string, ReviewerData[]>
	)

	return (
		Object.entries(grouped)
			// Sort object properties by type name; no type will always be last
			.sort((a, b) => {
				const aType = a[0].toLowerCase()
				const bType = b[0].toLowerCase()

				// Force untyped to the last position
				if (aType === NO_TYPE) return 1
				if (bType === NO_TYPE) return -1

				// Alphabetize
				return aType > bType ? 1 : aType === bType ? 0 : -1
			})
			// Convert object properties into an array
			.map(([, value]) => value)
	)
}
