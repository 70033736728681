import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Styling -----//

const Node = styled(Anchor)`
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	color: ${(p) => p.theme.color.primary.accent};
`

//----- Component -----//

export const AssetHyperlink = (props: RichTextContent): ReactElement | null => {
	if (props.data.target?.sys.type !== 'Asset') return null

	return (
		<Node to={props.data.target.fields.file.url}>
			<ContentAsReact content={props.content} />
		</Node>
	)
}
