import { ReactElement } from 'react'
import styled from 'styled-components'

import { Container, PageTitle } from '~/components/atoms'
import { SiteConfig } from '~/config'

//----- Styling -----//

const Description = styled.div`
	margin-bottom: 1.2em;
	max-width: 41em;
`

//----- Component -----//

export const MagazineTop = (): ReactElement => {
	return (
		<Container>
			<PageTitle>{SiteConfig.siteInfo.title}</PageTitle>
			<Description>{SiteConfig.siteInfo.magazineDescription}</Description>
		</Container>
	)
}
