import React from 'react'
import Head from 'next/head'

import type { ArticleData } from '~/types/content'
import { SiteConfig } from '~/config'

//----- Component -----//

export const MetaTags: React.FC<{
	article: ArticleData
	url: string
}> = ({ article, url }) => {
	const author = article.refs.authors ? article.refs.authors[0] : undefined
	const description = article.subtitle || article.title
	const imageUrl = article.image?.url?.startsWith('//')
		? `https:${article.image.url}`
		: article.image?.url

	return (
		<Head>
			<meta name="description" content={description} />

			<meta property="og:title" content={article.title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:url" content={url} />
			<meta property="og:site_name" content={SiteConfig.siteInfo.title} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={SiteConfig.social.twitterUser} />
			<meta name="twitter:creator" content={author?.twitterUsername} />
			<meta name="twitter:title" content={article.title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image:src" content={imageUrl} />
			<meta name="twitter:image:alt" content={article.image?.description} />

			<meta property="fb:app_id" content={SiteConfig.social.facebookAppID} />

			<link rel="canonical" href={url} />
		</Head>
	)
}
