import { ReactElement } from 'react'
import styled from 'styled-components'

import { Button_CalloutLink } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { usePaywallContext } from '~/contexts'

import { ModalPrompts } from '../../atoms'

//----- Styling -----//

const Banner = styled.div`
	display: none;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		display: grid;
		max-width: 1200px;
		min-height: 6.25em;
		margin: 0.5em auto;
		padding: 0 2em;
		column-gap: 1em;
		row-gap: 0.5em;
		grid-template-columns: auto 2fr auto auto;
		grid-template-areas: 'left midleft midright right';

		place-items: center;
	}
`

const Remaining = styled.div`
	grid-area: left;
	justify-self: right;
	text-align: right;

	font-size: 1.5em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`
const Callout = styled.div`
	grid-area: midleft;
	text-align: left;
	line-height: 1.5;

	font-size: 0.9em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`
const Subscribe = styled.div`
	grid-area: midright;
	justify-self: left;
`
const Prompts = styled.div`
	grid-area: right;
`

//----- Component -----//

export const FullCountdown = (): ReactElement | null => {
	const paywall = usePaywallContext()
	if (paywall.isEntitled) return null

	const subscribeUrl = SiteConfig.externalUrls.subscribeMeterBanner
	const articlePlural = `article${paywall.viewsRemaining === 1 ? '' : 's'}`

	return (
		<Banner>
			<Remaining>
				{paywall.viewsRemaining} free {articlePlural} left
			</Remaining>
			<Callout>
				Want More? Get unlimited access for as low as $1.99/month
			</Callout>
			<Subscribe>
				<Button_CalloutLink extraSlim inverted invertedBorder to={subscribeUrl}>
					Subscribe
				</Button_CalloutLink>
			</Subscribe>
			<Prompts>
				<ModalPrompts />
			</Prompts>
		</Banner>
	)
}
