import type { ReactElement } from 'react'

import type { ArticleData } from '~/types/content'

import { MetaTags, StructuredData } from './atoms'
import { BreadcrumbData } from './atoms/BreadcrumbData'

//----- Component -----//

export const ArticleMeta = (props: {
	article: ArticleData
	url: string
}): ReactElement => {
	const { article, url } = props

	const canonicalUrl = article.meta.canonicalUrl || url

	return (
		<>
			<MetaTags article={article} url={canonicalUrl} />
			<StructuredData article={article} url={canonicalUrl} />
			<BreadcrumbData article={article} />
		</>
	)
}
