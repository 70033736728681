import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'
import { Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'

//----- Styling -----//

const Node = styled(Anchor)`
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

export const Hyperlink = (
	props: JSX.IntrinsicElements['a'] & ExtraProps
): JSX.Element | null => {
	if (!props.href) return null

	const attrs: string[] = []
	const [linkTarget, linkAttrs] = props.href.split('|')

	if (linkAttrs) linkAttrs.split(',').forEach((a) => attrs.push(a))

	const to = linkTarget.startsWith(SiteConfig.siteInfo.rootUrl)
		? linkTarget.replace(SiteConfig.siteInfo.rootUrl, '')
		: linkTarget

	return (
		<Node color="accent" to={to} nofollow={attrs.includes('nofollow')}>
			{props.children}
		</Node>
	)
}
