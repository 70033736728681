import { ReactElement } from 'react'
import styled from 'styled-components'

import { Button_CalloutLink, Tile } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { usePageContext } from '~/contexts'
import { Analytics } from '~/util'

//----- Styling -----//

const Text = styled.p`
	margin: 16px 0;
`

const AccentText = styled.span`
	color: ${(p) => p.theme.color.primary.accent};
	font-weight: bold;
`

const ButtonWrapper = styled.div`
	margin: 0 auto;
`

const Button = styled(Button_CalloutLink)`
	font-size: 0.75em;
`

//----- Component -----//

export function SubscribeTile(): ReactElement {
	const page = usePageContext()

	const onSubscribe = () => {
		Analytics.getInstance().event({
			category: 'Paid Subscriber Click',
			action: 'Subscription Click',
			label: page.path
		})
	}

	return (
		<Tile title="Subscribe" subtitle="To The Magazine">
			<Text>
				Save up to <AccentText>40% off</AccentText> the cover price when you
				subscribe to <em>Discover</em> magazine.
			</Text>
			<ButtonWrapper>
				<Button to={SiteConfig.externalUrls.subscribe} onClick={onSubscribe}>
					Subscribe
				</Button>
			</ButtonWrapper>
		</Tile>
	)
}
