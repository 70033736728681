import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor_Internal, Image_Contentful } from '~/components/atoms'
import type { ArticleSummaryData } from '~/types/content'
import { getPath } from '~/util'

import { GalleryTag, SponsoredTag } from './atoms'
import { getImageUrl } from './util'

//----- Styling -----//

const Wrapper = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: [image] 128px [text] auto;
	grid-gap: 8px;

	padding-right: 2px;
`

const Image = styled(Image_Contentful)`
	width: 128px;
	height: auto;
	justify-self: stretch;
	grid-column: image;
`

const Text = styled.p`
	margin: 4px 0;

	font-family: ${(p) => p.theme.font.serif.family};
	font-size: 0.75em;
	line-height: 1.17;
	grid-column: text;

	/*
		This prevents longer titles from causing inconsistent gaps between images.
		The risk is that it could cause a title to overlap the following item, but
		we're making the assumption that most articles will not have overly long titles.
	*/
	max-height: 57px;
	overflow: visible;
`

//----- Component -----//

export function ArticleCard_Wide(props: {
	article: ArticleSummaryData
}): ReactElement | null {
	const { article } = props
	const categorySlug = article.refs.category?.slug || 'uncategorized'

	if (!article.slug) return null

	const articlePath = getPath.article(article.slug, categorySlug)
	const imageUrl = getImageUrl('sm', article.image?.url, categorySlug)

	return (
		<Anchor_Internal to={articlePath}>
			<Wrapper>
				<Image
					src={imageUrl}
					alt={article.title || 'Article'}
					maxWidth={350}
					maxHeight={233}
				/>
				<Text>{article.title}</Text>
				<GalleryTag template={article.meta.template} />
				<SponsoredTag template={article.meta.template} />
			</Wrapper>
		</Anchor_Internal>
	)
}
