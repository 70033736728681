import { ReactElement } from 'react'
import styled from 'styled-components'

import { Carousel, GalleryCard } from '~/components/molecules'
import type { GalleryCardData } from '~/types/content'

//----- Styling -----//

const size = {
	small: 328,
	medium: 486,
	large: 658
}

const StyledCarousel = styled(Carousel)`
	margin: 0 auto;
	width: ${size.small}px;

	@media (min-width: 569px) {
		width: ${size.medium}px;
	}

	@media (min-width: 739px) {
		width: ${size.large}px;
	}

	@media (min-width: 900px) {
		width: ${size.medium}px;
	}

	@media (min-width: 1071px) {
		width: ${size.large}px;
	}
`

//----- Component -----//

export const Gallery = (props: {
	cards: GalleryCardData[]
}): ReactElement | null => {
	if (!props.cards) return null

	// Always show only 1 card
	const config = {
		padding: '0',
		cardsToShow: 1,
		responsive: []
	}

	return (
		<StyledCarousel fixedArrows config={config}>
			{props.cards.map((card, i) => (
				<GalleryCard key={i} card={card} />
			))}
		</StyledCarousel>
	)
}
