import { ReactElement } from 'react'
import styled from 'styled-components'

import type { RichTextContent } from '~/types/content'

//----- Styling -----//

const TextNode = styled.span<{
	bold: boolean
	code: boolean
	italic: boolean
	underline: boolean
}>`
	${(p) => p.bold && `font-weight: ${p.theme.font.sans.weight.bold};`}
	${(p) => p.code && 'font-family: monospace;'}
	${(p) => p.italic && 'font-style: italic;'}
	${(p) => p.underline && 'text-decoration: underline;'}
`

//----- Component -----//

export const Text = (props: RichTextContent): ReactElement => {
	const bold = !!props.marks.find((mark) => mark.type === 'bold')
	const code = !!props.marks.find((mark) => mark.type === 'code')
	const italic = !!props.marks.find((mark) => mark.type === 'italic')
	const underline = !!props.marks.find((mark) => mark.type === 'underline')

	return (
		<TextNode bold={bold} code={code} italic={italic} underline={underline}>
			{props.value}
		</TextNode>
	)
}
