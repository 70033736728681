import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

import { TitleBar } from './atoms'

const Wrapper = styled.div`
	border-top: 3px solid ${(p) => p.theme.color.primary.contrast};
	border-bottom: 8px solid ${(p) => p.theme.color.primary.contrast};
`

const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;

	@media (min-width: 768px) {
		margin-bottom: 16px;
	}
`

const TileWrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, 1fr);
	min-height: 300px;
	margin-left: 8px;
	margin-right: 8px;
	justify-content: stretch;
	width: 100%;

	& > *:not(:last-child) {
		border-bottom: 2px solid ${(p) => p.theme.color.primary.contrast};
		margin-bottom: 8px;

		@media (min-width: 768px) {
			border-bottom: 0;
			margin-bottom: 0;
			border-right: 2px solid ${(p) => p.theme.color.gray[94]};
			margin-right: 8px;
		}
	}

	@media (min-width: 768px) {
		grid-template-rows: none;
		grid-template-columns: repeat(2, 1fr);
		max-width: 1200px;
		min-height: auto;
		max-height: 250px;
	}
`

export const TileBlock = (props: { children: ReactNode }): ReactElement => {
	return (
		<Wrapper>
			<TitleBar title="Stay Curious" />
			<ContentWrapper>
				<TileWrapper>{props.children}</TileWrapper>
			</ContentWrapper>
		</Wrapper>
	)
}
