import { ReactElement } from 'react'
import styled from 'styled-components'

//----- Styling -----//

const GalleryIcon = styled.img`
	position: absolute;
	top: 4px;
	left: 4px;
`

//----- Component -----//

interface GalleryTagProps {
	template?: string
}

export const GalleryTag = (props: GalleryTagProps): ReactElement | null => {
	if (props.template !== 'Gallery') return null

	return (
		<GalleryIcon
			src={'/assets/globalAssets/camera.png'}
			alt="Gallery Article"
		/>
	)
}
