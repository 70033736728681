import { ReactElement } from 'react'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Component -----//

export const Document = (props: RichTextContent): ReactElement | null => {
	if (!props.content) return null

	return <ContentAsReact content={props.content} />
}
