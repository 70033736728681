import { ListPageType } from '~/types/app'
import type { CommonListingData } from '~/types/content'
import type { ListingPageProps } from '~/types/page'

/**
 * Extracts the common content data for the given listing page type
 */
export function getCommonListData(
	listPageData: ListingPageProps
): CommonListingData | null {
	const { content, listType } = listPageData

	switch (listType) {
		case ListPageType.AUTHOR:
			return content.author || null

		case ListPageType.BLOG:
			return content.blog || null

		case ListPageType.CATEGORY:
			return content.category || null

		case ListPageType.DEPARTMENT:
			return content.department || null

		case ListPageType.REVIEWER:
			return content.reviewer || null

		case ListPageType.TAG:
			return content.tag || null

		default:
			return null
	}
}
