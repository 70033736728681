import styled from 'styled-components'

import { ZoomIn, ZoomOut, GetApp } from '@styled-icons/material'

import { Anchor_External } from '~/components/atoms'
import { Analytics } from '~/util'

//----- Styling -----//

const Controls = styled.div`
	position: absolute;
	right: 0;
	bottom: 0.5em;
	left: 0;
	text-align: center;
`

const ControlButton = styled.button`
	margin-right: 0.5em;
	border: 0;
	background: transparent;
	cursor: pointer;
`

const Anchor = styled(Anchor_External)`
	display: inline-block;
	margin-left: 2em;
`

//----- Component -----//

export const ControlButtons: React.FC<{
	onZoomOut: () => void
	onZoomIn: () => void
	downloadPath: string
}> = ({ onZoomOut, onZoomIn, downloadPath }) => {
	const pdfPath = downloadPath.replace('/api/archive', '')

	return (
		<Controls>
			<ControlButton aria-label="Zoom In" onClick={onZoomIn}>
				<ZoomIn size={32} />
			</ControlButton>
			<ControlButton aria-label="Zoom Out" onClick={onZoomOut}>
				<ZoomOut size={32} />
			</ControlButton>

			<Anchor
				onClick={() => Analytics.getInstance().archiveDownload(pdfPath)}
				to={downloadPath}
				download
				aria-label="Download PDF"
			>
				<GetApp size={32} />
			</Anchor>
		</Controls>
	)
}
