import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor_Internal, Image_Contentful } from '~/components/atoms'
import type { ArticleSummaryData } from '~/types/content'
import { getPath } from '~/util'

import { CategoryLink, GalleryTag, SponsoredTag } from './atoms'
import { getImageUrl } from './util'

//----- Styling -----//

const CardContainer = styled.div`
	padding-bottom: 2em;
`

const TitleAnchor = styled(Anchor_Internal)`
	display: inline-block;
	margin: 0.6em 0;
	font-family: ${(p) => p.theme.font.title.family};
	font-weight: ${(p) => p.theme.font.serif.weight.bold};
	font-size: 1.25em;
	line-height: 1.25;
`

const Subtitle = styled.div`
	font-family: ${(p) => p.theme.font.serif.family};
`

//----- Component -----//

export const ArticleCard_Hero = (props: {
	article?: ArticleSummaryData
}): ReactElement | null => {
	const { article } = props
	if (!article || !article.slug) return null

	const categorySlug = article.refs.category?.slug

	const articlePath = getPath.article(article.slug, categorySlug)
	const imageUrl = getImageUrl('lg', article.image?.url, categorySlug)

	return (
		<CardContainer>
			<div>
				<Anchor_Internal to={articlePath} style={{ position: 'relative' }}>
					<Image_Contentful
						src={imageUrl}
						alt={article.title || 'Article'}
						maxWidth={600}
						maxHeight={400}
						eager
						preload
					/>
					<GalleryTag template={article.meta.template} />
					<SponsoredTag template={article.meta.template} />
				</Anchor_Internal>
			</div>
			<div>
				<CategoryLink
					category={article.refs.category}
					template={article.meta.template}
				/>
			</div>
			<TitleAnchor to={articlePath}>{article.title}</TitleAnchor>
			<Subtitle>{article.subtitle}</Subtitle>
		</CardContainer>
	)
}
