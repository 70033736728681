import { ChangeEvent, ReactElement } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { Select } from '~/components/atoms'

//----- Styling -----//

const SelectWrap = styled.div`
	text-align: right;
	text-transform: uppercase;
`

//----- Component -----//

export const YearSelect = (props: {
	activeYear: string
	years: string[]
}): ReactElement | null => {
	const Router = useRouter()

	if (!props.years) return null

	const yearOptions = props.years.map((year) => ({ name: year, value: year }))

	function handleChange(event: ChangeEvent<HTMLSelectElement>): void {
		Router.replace('/magazine', `/magazine/${event.target.value}`)
	}

	return (
		<SelectWrap>
			<Select
				label={'Select Year'}
				options={yearOptions}
				default={props.activeYear}
				onChange={handleChange}
			/>
		</SelectWrap>
	)
}
