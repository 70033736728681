import styled from 'styled-components'
import { ReactElement } from 'react'

const TitleBackground = styled.div`
	display: flex;
	margin: 16px 0;
	padding: 0 16px;
	height: 32px;
	background: ${(p) => p.theme.color.gradients.diagonalGray};
`

const TextArea = styled.div`
	display: flex;
	align-items: center;

	padding: 16px;
	text-transform: uppercase;
	background: ${(p) => p.theme.color.primary.base};
`

export const TitleBar = (props: { title: string }): ReactElement => {
	return (
		<TitleBackground>
			<TextArea>{props.title}</TextArea>
		</TitleBackground>
	)
}
