import styled from 'styled-components'

import { Anchor } from '~/components/atoms'

export const PromptAnchor = styled(Anchor)`
	color: inherit;
	text-decoration: underline;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	transition: opacity 200ms ease-in-out;

	&:hover {
		opacity: 0.75;
	}
`
