import { ReactElement } from 'react'
import styled from 'styled-components'
import {
	List_Inline,
	ListItem_Inline,
	Container,
	PageSubtitle,
	PageTitle
} from '~/components/atoms'
import { ByLine } from '~/components/molecules'
import type {
	AuthorData,
	BlogData,
	CategoryData,
	ReviewerData,
	RichTextContent
} from '~/types/content'

import {
	ArticleIndexButton,
	BreadcrumbSeparator,
	CategoryLink,
	HomeLink
} from '../atoms'

//----- Styling -----//

const StyledContainer = styled(Container)`
	&::after {
		content: '';
		display: block;
		margin: 1em 0 2em;
		width: 2em;
		border-bottom: 5px double ${(p) => p.theme.color.primary.contrast};
	}
`

//----- Component -----//

interface ArticleTopProps {
	articleBody?: string | RichTextContent
	authors?: AuthorData[]
	blog?: BlogData
	category?: CategoryData
	isDesktop?: boolean
	indexVisibliity?: string
	modifiedDate?: string
	publicationDate?: string
	reviewers?: ReviewerData[]
	subtitle?: string
	title?: string
}
export const ArticleTop = (props: ArticleTopProps): ReactElement => (
	<StyledContainer>
		{props.isDesktop ? (
			<div id="articlePageTopDesktop"></div>
		) : (
			<>
				{props.indexVisibliity && props.indexVisibliity !== 'None' && (
					<ArticleIndexButton
						articleBody={props.articleBody}
						visibility={props.indexVisibliity}
					/>
				)}
				<div id="articlePageTopMobile"></div>
			</>
		)}
		<List_Inline gap={0.5}>
			<ListItem_Inline>
				<HomeLink />
			</ListItem_Inline>
			<ListItem_Inline>
				<BreadcrumbSeparator />
			</ListItem_Inline>
			<ListItem_Inline>
				<CategoryLink category={props.category} />
			</ListItem_Inline>
		</List_Inline>
		<PageTitle smallMargins retainCapitalization>
			{props.title || 'Article Title'}
		</PageTitle>
		<PageSubtitle>{props.subtitle}</PageSubtitle>
		<ByLine
			authors={props.authors}
			blog={props.blog}
			publicationDate={props.publicationDate}
			modifiedDate={props.modifiedDate}
			reviewers={props.reviewers}
		/>
	</StyledContainer>
)
