import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor, Tile } from '~/components/atoms'
import { NewsletterForm } from '~/components/molecules'
import { SiteConfig } from '~/config'

//----- Styling -----//

const Text = styled.p`
	margin: 16px 0;
`

const LinkWrapper = styled.div`
	width: 100%;
	text-align: center;
	margin: 16px;

	@media (min-width: 372px) {
		text-align: right;
		padding-right: 16px;
	}
`

export function NewsletterTile(): ReactElement {
	return (
		<Tile title="Join" subtitle="Our List">
			<Text>Sign up for our weekly science updates.</Text>
			<NewsletterForm signupSource="Join" />
			<LinkWrapper>
				<Anchor to={SiteConfig.externalUrls.privacyPolicy}>
					View our privacy policy
				</Anchor>
			</LinkWrapper>
		</Tile>
	)
}
