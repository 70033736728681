import { ReactElement } from 'react'
import styled from 'styled-components'

import { Copyright, Navigation, Social } from './molecules'

//----- Styling -----//

const UpperSection = styled.div`
	margin-top: 32px;
	padding: 16px;

	font-size: 1rem;
	color: ${(p) => p.theme.color.primary.base};

	background: ${(p) => p.theme.color.primary.contrast};

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		padding: 64px;
	}
`

const UpperInner = styled.div`
	position: relative;
	max-width: 1280px;
	margin: 0 auto;
	padding: 64px;

	border: 4px solid ${(p) => p.theme.color.primary.base};

	&:before {
		content: '';
		position: absolute;
		top: 4px;
		right: 4px;
		bottom: 4px;
		left: 4px;
		border: 4px solid ${(p) => p.theme.color.primary.base};

		pointer-events: none;
	}
`

const LowerSection = styled.div`
	padding: 16px 16px 64px;

	font-size: 0.75em;
	text-transform: uppercase;
	text-align: center;

	border-bottom: 16px solid ${(p) => p.theme.color.primary.accent};
`

//----- Component -----//

export const Footer = (): ReactElement => {
	return (
		<>
			<UpperSection>
				<UpperInner>
					<Social />
					<Navigation />
				</UpperInner>
			</UpperSection>

			<LowerSection>
				<Copyright />
			</LowerSection>
		</>
	)
}
