import { ReactElement } from 'react'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact } from '../util'

//----- Component -----//

export const Paragraph = (props: RichTextContent): ReactElement => {
	return (
		<p>
			<ContentAsReact content={props.content} />
		</p>
	)
}
