import { ReactElement } from 'react'
import styled from 'styled-components'

import { Button_CalloutLink, Image } from '~/components/atoms'
import { SiteConfig } from '~/config'

import { ModalPrompts } from '../../atoms'

//----- Styling -----//

const AreaWrap = styled.div<{ expanded: boolean }>`
	background: ${(p) => p.theme.color.primary.contrast};

	max-height: ${(p) => (p.expanded ? '345px' : '0')};
	transition: max-height 300ms ease-in-out;
	overflow: hidden;

	text-align: center;
`

const AreaInner = styled.div`
	padding: 1em;
`

const MainCallout = styled.div`
	margin-top: 0.75em;
	font-size: 1.5em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`
const SmallCallout = styled.p`
	font-size: 0.85em;
	font-weight: ${(p) => p.theme.font.sans.weight.semiBold};
	letter-spacing: ${(p) => p.theme.font.sans.spacing.slim};

	color: ${(p) => p.theme.color.tertiary.accent};
`

//----- Component -----//

export const SlideoutArea = (props: {
	expanded: boolean
}): ReactElement | null => {
	const subscribeUrl = SiteConfig.externalUrls.subscribe

	return (
		<AreaWrap expanded={props.expanded}>
			<AreaInner>
				<Image
					src="/assets/globalAssets/DSC_white_small.png"
					alt="Discover Magazine Logo"
					maxWidth={215}
					eager
				/>

				<MainCallout>Want more?</MainCallout>
				<SmallCallout>Keep reading for as low as $1.99!</SmallCallout>
				<p>
					<Button_CalloutLink extraSlim inverted to={subscribeUrl}>
						Subscribe
					</Button_CalloutLink>
				</p>
				<ModalPrompts />
			</AreaInner>
		</AreaWrap>
	)
}
