import { ReactElement } from 'react'
import styled from 'styled-components'

import { Button_CalloutLink, Image } from '~/components/atoms'
import { SiteConfig } from '~/config'

import { Paragraph } from '../atoms'

//----- Styling -----//

const Callout = styled(Paragraph)`
	margin: 0.5em 0;
	font-size: 1.125em;
	line-height: 1.4;
	color: ${(p) => p.theme.color.tertiary.accent};
`

//----- Component -----//

export const ModalContent = (): ReactElement => {
	const { subscribePaywallPrompt } = SiteConfig.externalUrls

	return (
		<section>
			<Image
				src="/assets/globalAssets/DSC_white_small.png"
				alt="Discover Magazine Logo"
				maxWidth={174}
			/>
			<Callout>
				Want more?
				<br />
				Keep reading for as low as $1.99!
			</Callout>
			<div>
				<Button_CalloutLink extraSlim inverted to={subscribePaywallPrompt}>
					Subscribe
				</Button_CalloutLink>
			</div>
		</section>
	)
}
