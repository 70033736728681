import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { getPath } from '~/util'

import { IssueArticles } from './molecules'
import { IssueCover, IssueSubscribe, IssueTitle } from './atoms'
import type { ArticleSummaryData, IssueData } from '~/types/content'

//----- Styling -----//

const Background = styled.div`
	padding: 32px 0;
	background: ${(p) => p.color || p.theme.color.secondary.accent};
`

const Inner = styled.div`
	position: relative;
	width: 100%;
	max-width: 350px;
	margin: 0 auto;
	padding: 32px;

	border: 6px solid ${(p) => p.theme.color.primary.contrast};

	&::before {
		content: '';
		position: absolute;
		top: 1px;
		right: 1px;
		bottom: 1px;
		left: 1px;

		border: 6px solid ${(p) => p.theme.color.primary.contrast};
		box-shadow: 0px 0px 0px 1px ${(p) => p.theme.color.primary.base};
		pointer-events: none;
	}

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		max-width: 720px;
		display: flex;

		border-width: 4px;

		&::before {
			border-width: 3px;
		}
	}
`

const LeftColumn = styled.div`
	text-align: center;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		flex-basis: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		text-align: left;
	}
`

const RightColumn = styled.div`
	text-align: center;
	margin-top: 1.5em;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		text-align: left;
		margin-top: 0;
	}
`

//----- Component -----//

export const IssueCallout = (props: {
	issue: IssueData
	articles: ArticleSummaryData[]
}): ReactElement | null => {
	if (!props.issue || !props.issue.publication) return null

	const issuePath = getPath.issue(
		props.issue.slug,
		props.issue.publication.date
	)

	return (
		<Background color={props.issue.backgroundColor}>
			<Inner>
				<LeftColumn>
					{props.issue.coverImage?.url && (
						<IssueCover
							imageUrl={props.issue.coverImage.url}
							issueUrl={issuePath}
						/>
					)}
				</LeftColumn>
				<RightColumn>
					<IssueTitle issueUrl={issuePath}>
						{props.issue.publication.period}
					</IssueTitle>
					<IssueArticles articles={props.articles} />
					<IssueSubscribe />
				</RightColumn>
			</Inner>
		</Background>
	)
}
