import React from 'react'
import styled from 'styled-components'

import { useAppContext } from '~/contexts'

import { SlideoutFooter, SlideoutHeader, SlideoutLinkList } from './molecules'

//----- Styling -----//

const SlideoutWrap = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	font-size: 1rem;
	pointer-events: none;

	z-index: ${(p) => p.theme.misc.zIndex.slideoutNav};
`

const Content = styled.div.attrs<
	{ isOpen: boolean },
	{ smWidth: number; mdWidth: number }
>(() => ({
	smWidth: 262,
	mdWidth: 350
}))<{ isOpen: boolean }>`
	position: absolute;
	top: 0;
	bottom: 0;

	left: ${(props) => (props.isOpen ? 0 : `-${props.smWidth}px`)};
	width: ${(props) => `${props.smWidth}px`};

	@media (min-width: 576px) {
		left: ${(props) => (props.isOpen ? 0 : `-${props.mdWidth}px`)};
		width: ${(props) => `${props.mdWidth}px`};
	}

	background-color: ${({ theme }) => theme.color.primary.contrast};
	color: ${({ theme }) => theme.color.primary.base};

	overflow-y: auto;

	transition: left 200ms ease-in-out;

	pointer-events: auto;
`

//----- Component -----//

export const SlideoutNav = () => {
	const { slideoutMenuIsOpen } = useAppContext()

	return (
		<SlideoutWrap>
			<Content isOpen={slideoutMenuIsOpen}>
				<SlideoutHeader />
				<SlideoutLinkList />
				<SlideoutFooter />
			</Content>
		</SlideoutWrap>
	)
}
