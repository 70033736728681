import { ReactElement } from 'react'

import { AuthorAvatar, Container, PageTitle } from '~/components/atoms'
import type { ImageData } from '~/types/content'

export const ListingTop = (props: {
	avatarImage: ImageData | null
	title?: string
	isDesktop?: boolean
}): ReactElement => (
	<Container>
		{props.isDesktop ? (
			<div id="listingPageTopDesktop"></div>
		) : (
			<div id="listingPageTopMobile"></div>
		)}
		<PageTitle>
			{props.avatarImage && (
				<AuthorAvatar
					title={props.avatarImage.title || props.title}
					url={props.avatarImage.url}
				/>
			)}
			{props.title || 'Author'}
		</PageTitle>
	</Container>
)
