import {
	ChangeEvent,
	ComponentProps,
	FormEvent,
	ReactElement,
	useState
} from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import {
	Button_Solid,
	LoadingSpinner_Ring,
	ResultMessage
} from '~/components/atoms'
import { InputBlock } from '~/components/molecules'
import { SiteConfig } from '~/config'
import { useAppContext, usePageContext, useUserContext } from '~/contexts'
import { ApiClient, constants, validate } from '~/util'

//----- Constants -----//

type ResultMessageProps = ComponentProps<typeof ResultMessage>
type Event = ChangeEvent<HTMLInputElement>

// Validation errors
const invalidEmailMsg: ResultMessageProps = {
	text: 'Please provide a valid email address',
	variant: 'warn'
}
const invalidPassMsg: ResultMessageProps = {
	text: 'Please provide a password',
	variant: 'warn'
}

// Request errors
const authErrMsg: ResultMessageProps = {
	text: 'Email and/or password is invalid.',
	variant: 'fail'
}
const otherErrMsg: ResultMessageProps = {
	text: 'An error has occurred. Please try again.',
	variant: 'fail'
}

//----- Styling -----//

const StyledButton = styled(Button_Solid)`
	width: 100%;
`

//----- Component -----//

export const LoginForm = (): ReactElement => {
	const app = useAppContext()
	const page = usePageContext()
	const user = useUserContext()
	const router = useRouter()

	//----- Component Field State Vars -----//
	const [userEmail, setUserEmail] = useState('')
	const [userPass, setUserPass] = useState('')
	const [loading, setLoading] = useState(false)
	//----- Component Message State Vars -----//
	const [errorMsg, setErrorMsg] = useState<ResultMessageProps>({})
	const [emailMsg, setEmailMsg] = useState<ResultMessageProps>({})
	const [passMsg, setPassMsg] = useState<ResultMessageProps>({})

	//----- Component Event Handlers -----//
	function validateEmail(): boolean {
		const isValid = validate.email(userEmail)
		setEmailMsg(isValid ? {} : invalidEmailMsg)
		return isValid
	}

	function validatePassword(): boolean {
		const isValid = validate.nonEmptyString(userPass)
		setPassMsg(isValid ? {} : invalidPassMsg)
		return isValid
	}

	async function handleSubmit(
		event: FormEvent<HTMLFormElement>
	): Promise<void> {
		// Don't let the form actually submit
		event.preventDefault()

		// Clear any prior errors
		setErrorMsg({})

		// Validate user input
		if (!validateEmail() || !validatePassword()) return

		// Set loading status & attempt to log in
		setLoading(true)
		const success = await ApiClient.login(userEmail, userPass)

		// Handle error, if present
		if (success !== true) {
			const { ERR_NOAUTH } = constants.httpStatus
			setLoading(false)
			setErrorMsg(success === ERR_NOAUTH ? authErrMsg : otherErrMsg)
			return
		}

		// Fetch user data for newly logged-in user
		const [userData, optinData] = await Promise.all([
			await ApiClient.getUser(),
			await ApiClient.getNewsletterOptins()
		])

		// Update the state with
		if (user)
			user.hydrate({
				...userData,
				hasNewsletterSub:
					optinData && optinData.includes(SiteConfig.siteInfo.kservDomain)
			})

		// Clear loading status
		setLoading(false)

		// Handle success
		setErrorMsg({})
		user.setIsLoggedIn(true)
		app.toggleLoginModal()
		// Refresh the current page & user data
		router.replace(page.path || '/')
	}

	return (
		<form onSubmit={handleSubmit}>
			<InputBlock
				caption="Email Address"
				message={emailMsg}
				input={{
					autoComplete: 'username',
					onChange: (e: Event) => setUserEmail(e.target.value),
					onBlur: validateEmail,
					tall: true,
					fullWidth: true
				}}
			/>

			<InputBlock
				caption="Password"
				message={passMsg}
				input={{
					type: 'password',
					autoComplete: 'current-password',
					onChange: (e: Event) => setUserPass(e.target.value),
					onBlur: validatePassword,
					tall: true,
					fullWidth: true
				}}
			/>

			<ResultMessage {...errorMsg} center big />
			<StyledButton type="submit">
				Login
				{loading && <LoadingSpinner_Ring variant="base" size={14} floating />}
			</StyledButton>
		</form>
	)
}
