import { Analytics } from '~/util'

type ScrollThreshold = {
	percent: number
	hasScrolledPast: boolean
}

/**
 * Generate a clean dataset of scroll thresholds
 */
export function getNewScrollThresholds(): ScrollThreshold[] {
	const thresholds = [0, 25, 50, 75, 100, 125, 150]

	return [...thresholds.map((percent) => ({ percent, hasScrolledPast: false }))]
}

export function checkScrollEvent(
	articlePath: string,
	richText: HTMLDivElement | null,
	thresholds: ScrollThreshold[]
): ScrollThreshold[] {
	// No richText node? No action needed
	if (!richText) return thresholds

	// Determine how far the user has scrolled over the RichText node's content
	const current = window.innerHeight + window.scrollY - richText.offsetTop
	const max = richText.offsetHeight

	const percent = (current / max) * 100

	// Review each threshold...
	return [...thresholds].map((threshold) => {
		// If the user has scrolled past it for the first time...
		if (percent >= threshold.percent && !threshold.hasScrolledPast) {
			// Store the hasScrolledPast state and trigger analytics event
			threshold.hasScrolledPast = true
			Analytics.getInstance().scrollEvent(articlePath, threshold.percent)
		}
		return threshold
	})
}
