import {
	ChangeEvent,
	ComponentProps,
	FormEvent,
	ReactElement,
	useState
} from 'react'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'

import { Anchor, ResultMessage } from '~/components/atoms'
import { InputBlock } from '~/components/molecules'
import { SiteConfig } from '~/config'
import { useAppContext, useUserContext } from '~/contexts'
import { validate } from '~/util'

import { FormButton, PrivacyNote, ViewTitle } from '../../atoms'

//----- Constants -----//

type ResultMessageProps = ComponentProps<typeof ResultMessage>
type Event = ChangeEvent<HTMLInputElement>

// Validation errors
const invalidFirstName: ResultMessageProps = {
	text: 'First name is Required',
	variant: 'warn'
}
const invalidLastName: ResultMessageProps = {
	text: 'Last name is Required',
	variant: 'warn'
}
const invalidEmail: ResultMessageProps = {
	text: 'Please provide a valid email address',
	variant: 'warn'
}
const invalidPass: ResultMessageProps = {
	text: 'Password must be 6 characters',
	variant: 'warn'
}
const invalidConfirm: ResultMessageProps = {
	text: 'Must match Password',
	variant: 'warn'
}

//----- Styling -----//

const CaptchaWrap = styled.div`
	div {
		margin: 0.25em auto 0.75em;
	}
`

const HalfWidth = styled.div`
	@media (min-width: 400px) {
		display: inline-block;

		width: calc(50% - 0.5em);

		& + & {
			margin-left: 1em;
		}
	}
`

//----- Component -----//

export const RegisterView = (props: { showNext: () => void }): ReactElement => {
	const { setLoginModalOpen, setRegisterModalOpen } = useAppContext()
	const user = useUserContext()
	const { externalUrls, vendor } = SiteConfig

	//----- Component Field State Vars -----//
	const [userFirstName, setUserFirstName] = useState(user.firstName)
	const [userLastName, setUserLastName] = useState(user.lastName)
	const [userEmail, setUserEmail] = useState(user.email)
	const [userPass, setUserPass] = useState('')
	const [userConfirm, setUserConfirm] = useState('')
	const [userCaptcha, setUserCaptcha] = useState<string | null>(null)
	//----- Component Message State Vars -----//
	const [firstNameMsg, setFirstNameMsg] = useState<ResultMessageProps>({})
	const [lastNameMsg, setLastNameMsg] = useState<ResultMessageProps>({})
	const [emailMsg, setEmailMsg] = useState<ResultMessageProps>({})
	const [passMsg, setPassMsg] = useState<ResultMessageProps>({})
	const [confirmMsg, setConfirmMsg] = useState<ResultMessageProps>({})

	//----- Component Event Handlers -----//
	function validateFirstName(): boolean {
		const isValid = validate.nonEmptyString(userFirstName)
		setFirstNameMsg(isValid ? {} : invalidFirstName)
		return isValid
	}
	function validateLastName(): boolean {
		const isValid = validate.nonEmptyString(userLastName)
		setLastNameMsg(isValid ? {} : invalidLastName)
		return isValid
	}
	function validateEmail(): boolean {
		const isValid = validate.email(userEmail)
		setEmailMsg(isValid ? {} : invalidEmail)
		return isValid
	}
	function validatePass(): boolean {
		const isValid = validate.password(userPass)
		setPassMsg(isValid ? {} : invalidPass)
		return isValid
	}
	function validateConfirm(): boolean {
		const isValid =
			validate.equal(userPass, userConfirm) &&
			validate.nonEmptyString(userConfirm)
		setConfirmMsg(isValid ? {} : invalidConfirm)
		return isValid
	}
	function validateAll(): boolean {
		let valid = true
		if (!validateFirstName()) valid = false
		if (!validateLastName()) valid = false
		if (!validateEmail()) valid = false
		if (!validatePass()) valid = false
		if (!validateConfirm()) valid = false
		// Captcha only needs to be valid in production
		if (process.env.NODE_ENV !== 'development' && !userCaptcha) valid = false
		return valid
	}

	function showLoginForm() {
		setRegisterModalOpen(false)
		setLoginModalOpen(true)
	}

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault()

		if (!validateAll()) return

		// Set user information in the global context
		const userData = {
			firstName: userFirstName,
			lastName: userLastName,
			email: userEmail,
			password: userPass
		}
		user.hydrate(userData, true)

		// Proceed to the next step
		props.showNext()
	}

	return (
		<form onSubmit={handleSubmit}>
			<ViewTitle>Enter your name and email address below.</ViewTitle>

			<HalfWidth>
				<InputBlock
					input={{
						autoComplete: 'given-name',
						tall: true,
						fullWidth: true,
						onChange: (e: Event) => setUserFirstName(e.target.value),
						onBlur: validateFirstName,
						value: userFirstName
					}}
					caption="First Name"
					message={firstNameMsg}
				/>
			</HalfWidth>
			<HalfWidth>
				<InputBlock
					input={{
						autoComplete: 'family-name',
						tall: true,
						fullWidth: true,
						onChange: (e: Event) => setUserLastName(e.target.value),
						onBlur: validateLastName,
						value: userLastName
					}}
					caption="Last Name"
					message={lastNameMsg}
				/>
			</HalfWidth>

			<InputBlock
				input={{
					autoComplete: 'email',
					tall: true,
					fullWidth: true,
					onChange: (e: Event) => setUserEmail(e.target.value),
					onBlur: validateEmail,
					value: userEmail
				}}
				caption="Email"
				message={emailMsg}
			/>

			<HalfWidth>
				<InputBlock
					input={{
						autoComplete: 'new-password',
						type: 'password',
						tall: true,
						fullWidth: true,
						onChange: (e: Event) => setUserPass(e.target.value),
						onBlur: validatePass
					}}
					caption="Password"
					message={passMsg}
				/>
			</HalfWidth>
			<HalfWidth>
				<InputBlock
					input={{
						autoComplete: 'new-password',
						type: 'password',
						tall: true,
						fullWidth: true,
						onChange: (e: Event) => setUserConfirm(e.target.value),
						onBlur: validateConfirm
					}}
					caption="Confirm Password"
					message={confirmMsg}
				/>
			</HalfWidth>

			<CaptchaWrap>
				<ReCAPTCHA
					sitekey={vendor.googleReCaptcha}
					onChange={(token) => setUserCaptcha(token)}
				/>
			</CaptchaWrap>

			<PrivacyNote>
				Your email address is used to log in and will not be shared or sold.{' '}
				<Anchor to={externalUrls.privacyPolicy}>
					Read our privacy policy.
				</Anchor>
			</PrivacyNote>

			<FormButton type="submit">Continue</FormButton>
			<FormButton type="reset" variant="contrast" onClick={showLoginForm}>
				Log In
			</FormButton>
		</form>
	)
}
