import React, { useState } from 'react'
import styled from 'styled-components'

import { ArticleIndex } from '~/components/organisms'
import { RichTextContent } from '~/types/content'
import { List } from '@styled-icons/material'

//----- Styling -----//

const OuterWrapper = styled.div`
	position: fixed;
	top: 5.8em;
	right: 2em;
	max-width: calc(100% - 4em);

	/* Make sure this appears above ads, instaread, etc. */
	z-index: 1000;

	@media (min-width: 808px) {
		top: 9.3em;
	}
`

const ButtonWrapper = styled.div<{ indexVisible: boolean }>`
	text-align: right;

	& > button {
		padding: 0.25em 0.5em;
		border: 1px solid ${(p) => p.theme.color.primary.contrast};
		background: ${(p) => p.theme.color.primary.base};
		cursor: pointer;
		${(p) => (p.indexVisible ? 'border-bottom: 0;' : '')}
	}
`

const IndexWrapper = styled.div`
	margin-top: -1px;
	padding: 1em;
	background-color: ${(p) => p.theme.color.primary.base};
	border: 1px solid ${(p) => p.theme.color.primary.contrast};
`

//----- Component -----//

export const ArticleIndexButton: React.FC<{
	articleBody?: string | RichTextContent
	visibility?: string
}> = (props) => {
	const [indexVisible, setIndexVisible] = useState(true)

	return (
		<OuterWrapper>
			<ButtonWrapper indexVisible={indexVisible}>
				<button onClick={() => setIndexVisible(!indexVisible)}>
					<List width={32} />
				</button>
			</ButtonWrapper>
			{indexVisible && (
				<IndexWrapper>
					<ArticleIndex
						articleBody={props.articleBody}
						visibility={props.visibility}
					/>
				</IndexWrapper>
			)}
		</OuterWrapper>
	)
}
