import React from 'react'
import { ExtraProps } from 'react-markdown'

//----- Component -----//

export const Heading5 = (
	props: JSX.IntrinsicElements['h4'] & ExtraProps
): JSX.Element => {
	const { children, node, ref, ...rest } = props

	return <h5 {...rest}>{children}</h5>
}
