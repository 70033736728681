import { ReactElement } from 'react'
import styled from 'styled-components'

import type { AuthorData, BlogData, ReviewerData } from '~/types/content'

import { Blog, ModifiedDate, Person, PublicationDate } from './atoms'
import { getReviewersByType } from './util'

//----- Styling -----//

const StyledByLine = styled.div`
	margin-top: 1.325em;
	font-size: 0.75em;
	line-height: 1.5;
`

//----- Component -----//

interface ByLineProps {
	authors?: AuthorData[]
	blog?: BlogData
	publicationDate?: string
	modifiedDate?: string
	reviewers?: ReviewerData[]
}

export const ByLine = (props: ByLineProps): ReactElement | null => {
	const { authors, blog, publicationDate, modifiedDate, reviewers } = props

	return (
		<StyledByLine>
			{blog && (
				<div>
					<Blog blog={blog} />
				</div>
			)}
			<div>
				{authors?.map((author, i) => (
					<Person
						key={i}
						i={i}
						total={authors.length}
						person={author}
						type="Author"
					/>
				))}
				{getReviewersByType(reviewers).map(
					(reviewerGroup, i) =>
						reviewerGroup?.map((reviewer, j) => (
							<Person
								key={`${i}-${j}`}
								i={j}
								total={reviewerGroup.length}
								person={reviewer}
								type="Reviewer"
							/>
						))
				)}
			</div>
			<div>
				<PublicationDate date={publicationDate} />
				<ModifiedDate date={modifiedDate} publicationDate={publicationDate} />
			</div>
		</StyledByLine>
	)
}
