import React from 'react'
import { ExtraProps } from 'react-markdown'
import styled from 'styled-components'

//----- Styling -----//

const Node = styled.blockquote`
	margin: 0;
	padding: 0.4em 0;

	font-size: 1.25em;
	text-align: center;
`

//----- Component -----//

export const Blockquote = (
	props: JSX.IntrinsicElements['blockquote'] & ExtraProps
): JSX.Element => {
	const { node, ref, ...rest } = props

	return <Node {...rest} />
}
