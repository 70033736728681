import { ReactElement, useState } from 'react'
import styled from 'styled-components'

import { Whitespace } from '~/components/atoms'
import { NewsletterForm } from '~/components/molecules'
import { SiteConfig } from '~/config'

import { NewsletterSelection, PrivacyInfo } from '../molecules'

//----- Styling -----//

const List = styled.ul`
	margin: 0;
	padding: 0;

	& > li + li {
		border-top: 1px solid ${(p) => p.theme.color.gray[84]};
	}
`

//----- Component -----//

export const NewsletterSignup = (): ReactElement => {
	const [selections, setSelections] = useState<string[]>([])

	// Add or remove subscription to state based on isSelected
	const onItemChange = (name: string, isSelected: boolean) => {
		const items = [...selections]

		const position = items.indexOf(name)

		if (isSelected && position === -1) items.push(name)
		if (!isSelected && position !== -1) items.splice(position, 1)

		setSelections(items)
	}

	return (
		<section>
			<List>
				{SiteConfig.newsletters.map((newsletter) => (
					<NewsletterSelection
						key={newsletter.code}
						newsletter={newsletter}
						onChange={onItemChange}
						selected={selections.includes(newsletter.code)}
					/>
				))}
			</List>
			<Whitespace xs={'1em'} />
			<NewsletterForm signupSource="News" subscriptions={selections} />
			<Whitespace xs={'1.5em'} />
			<PrivacyInfo />
		</section>
	)
}
