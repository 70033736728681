import { RefObject } from 'react'
import styled from 'styled-components'
import { Category } from '~/types/category'

import type { RichTextContent } from '~/types/content'

import { ContentAsReact, RichTextProvider } from './util'
import { withInsticator } from './util/withInsticator'

//----- Styling -----//

const Content = styled.article`
	line-height: 24px;
`

//----- Component -----//

export const RichText: React.FC<{
	category?: Category
	rootNode?: RichTextContent | string
	contentRef?: RefObject<HTMLDivElement>
	template?: string
}> = ({ category, rootNode, contentRef, template }) => {
	if (!rootNode) return null
	if (typeof rootNode === 'string') return <Content>{rootNode}</Content>

	return (
		<RichTextProvider value={{ template: template, category: category }}>
			<Content ref={contentRef}>
				<ContentAsReact content={[withInsticator(rootNode)]} />
			</Content>
		</RichTextProvider>
	)
}
