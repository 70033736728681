import { Fragment, ReactElement } from 'react'

import { SiteConfig } from '~/config'
import type { RichTextContent } from '~/types/content'

import {
	AssetHyperlink,
	Blockquote,
	Document,
	EmbeddedAsset,
	EmbeddedEntry,
	EntryHyperlink,
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	Heading6,
	HorizontalRule,
	Hyperlink,
	Insticator,
	ListItem,
	OrderedList,
	Paragraph,
	Text,
	UnorderedList,
	Unsupported
} from '../atoms'

const { BLOCKS, CUSTOM, INLINES } = SiteConfig.richText

function getNodeByType(node: RichTextContent) {
	switch (node.nodeType) {
		case BLOCKS.DOCUMENT:
			return <Document {...node} />

		case BLOCKS.EMBEDDED_ASSET:
			return <EmbeddedAsset {...node} />

		case BLOCKS.EMBEDDED_ENTRY:
			return <EmbeddedEntry {...node} />

		case BLOCKS.HEADING_1:
			return <Heading1 {...node} />

		case BLOCKS.HEADING_2:
			return <Heading2 {...node} />

		case BLOCKS.HEADING_3:
			return <Heading3 {...node} />

		case BLOCKS.HEADING_4:
			return <Heading4 {...node} />

		case BLOCKS.HEADING_5:
			return <Heading5 {...node} />

		case BLOCKS.HEADING_6:
			return <Heading6 {...node} />

		case BLOCKS.HR:
			return <HorizontalRule />

		case BLOCKS.LIST_ITEM:
			return <ListItem {...node} />

		case BLOCKS.OL_LIST:
			return <OrderedList {...node} />

		case BLOCKS.PARAGRAPH:
			return <Paragraph {...node} />

		case BLOCKS.QUOTE:
			return <Blockquote {...node} />

		case BLOCKS.UL_LIST:
			return <UnorderedList {...node} />

		case CUSTOM.INSTICATOR:
			return <Insticator />

		case INLINES.ASSET_HYPERLINK:
			return <AssetHyperlink {...node} />

		case INLINES.ENTRY_HYPERLINK:
			return <EntryHyperlink {...node} />

		case INLINES.HYPERLINK:
			return <Hyperlink {...node} />

		case INLINES.TEXT:
			return <Text {...node} />

		default:
			return <Unsupported {...node} />
	}
}

export const ContentAsReact = (props: {
	content?: RichTextContent[]
}): ReactElement | null => {
	if (!props.content) return null

	return (
		<>
			{props.content.map((node, key) => (
				<Fragment key={key}>{getNodeByType(node)}</Fragment>
			))}
		</>
	)
}
