import React from 'react'
import Head from 'next/head'
import type { Article, FAQPage, WithContext } from 'schema-dts'

import type { ArticleData, AuthorData } from '~/types/content'

import { SiteConfig } from '~/config'

// Schema can be either of these page types
type PageSchema = Article | FAQPage

//----- Util Functions -----//

function getSchemaType(articleType?: string): PageSchema['@type'] {
	if (articleType === 'News') return 'NewsArticle'
	if (articleType === 'FAQ') return 'FAQPage'

	return 'Article'
}

function getArticleSection(
	category = 'Discover',
	articleType = 'Article'
): Article['articleSection'] | FAQPage['@type'] {
	return `${category} ${articleType}`
}

function getAuthorSchema(author?: AuthorData): PageSchema['author'] {
	if (!author?.name) return undefined

	return {
		'@type': 'Person',
		name: author.name,
		url: `${SiteConfig.siteInfo.rootUrl}/author/${author.slug}`
	}
}

function getThumbnailUrl(imageUrl?: string): PageSchema['thumbnailUrl'] {
	if (!imageUrl) return undefined

	return `${imageUrl}?fm=jpg&fl=progressive&w=350&h=233&fit=fill`
}

//----- Component -----//

export const StructuredData: React.FC<{
	article: ArticleData
	url: string
}> = ({ article, url }) => {
	const author = article.refs.authors ? article.refs.authors[0] : undefined
	const imageUrl = article.image?.url?.startsWith('//')
		? `https:${article.image.url}`
		: article.image?.url

	const schema: WithContext<PageSchema> = {
		'@context': 'https://schema.org',
		'@type': getSchemaType(article.meta.articleType),
		mainEntityOfPage: url,
		headline: article.title,
		articleSection: getArticleSection(
			article.refs.category?.name,
			article.meta.articleType
		),
		description: article.subtitle || article.title,
		keywords: article.refs.tags
			?.map((tag) => tag.name)
			.filter((keyword) => !!keyword) as string[],
		url,
		image: imageUrl,
		thumbnailUrl: getThumbnailUrl(imageUrl),
		datePublished: article.meta.publicationDate,
		dateModified: article.meta.modifiedDate,
		author: getAuthorSchema(author),
		publisher: {
			name: 'Discover Magazine',
			'@type': 'Organization',
			logo: { '@type': 'ImageObject', ...SiteConfig.siteInfo.logoImage }
		},
		isAccessibleForFree: 'False',
		hasPart: {
			'@type': 'WebPageElement',
			isAccessibleForFree: 'False',
			cssSelector: 'article'
		},
		// Override defaults with anything provided by the Author
		...article.meta.seoSchema
	}

	return (
		<Head>
			<script
				key="structured-data"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
			/>
		</Head>
	)
}
