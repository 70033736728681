import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'

//----- Styling -----//

const Header = styled.h2`
	margin: 0;
`

const StyledAnchor = styled(Anchor)`
	color: ${(p) => p.theme.color.primary.base.contrast};
	font-family: ${(p) => p.theme.font.title.family};
	font-weight: ${(p) => p.theme.font.title.weight.bold};
	text-transform: uppercase;
`

//----- Component -----//

export const IssueTitle = (props: {
	issueUrl: string
	children: ReactNode
}): ReactElement => (
	<Header>
		<StyledAnchor to={props.issueUrl}>{props.children}</StyledAnchor>
	</Header>
)
