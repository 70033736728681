import { ReactElement } from 'react'

import type { RichTextContent } from '~/types/content'
import { ContentAsReact, getHeadingData } from '../util'
import { AnchorOffset } from '~/components/atoms'

//----- Component -----//

export const Heading6 = (props: RichTextContent): ReactElement => (
	<AnchorOffset id={getHeadingData(props)?.slug}>
		<h6>
			<ContentAsReact content={props.content} />
		</h6>
	</AnchorOffset>
)
