import { ReactElement } from 'react'

import { HorizontalRule } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { useAppContext, useUserContext } from '~/contexts'

import { Paragraph, PromptAnchor } from '../atoms'

//----- Component -----//

export const ModalFooter = (): ReactElement => {
	const { existingSubscriber } = SiteConfig.externalUrls
	const app = useAppContext()
	const user = useUserContext()

	return (
		<section>
			<HorizontalRule inverted />
			{!user.isLoggedIn && (
				<Paragraph>
					<PromptAnchor onClick={app.toggleLoginModal}>Log In</PromptAnchor> or{' '}
					<PromptAnchor onClick={app.toggleRegisterModal}>
						Register
					</PromptAnchor>
				</Paragraph>
			)}
			<Paragraph>
				Already a subscriber?
				<br />
				{user.isLoggedIn && (
					<>
						<PromptAnchor onClick={app.toggleAccessCodeModal}>
							Enter Access Code
						</PromptAnchor>{' '}
						or{' '}
					</>
				)}
				<PromptAnchor to={existingSubscriber}>
					Find my Subscription
				</PromptAnchor>
			</Paragraph>
		</section>
	)
}
