import { ReactElement } from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'

//----- Styling -----//

const DateWrapper = styled.span`
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

export const PublicationDate = (props: {
	date?: string
}): ReactElement | null => {
	if (!props.date) return null

	return <DateWrapper>{format(new Date(props.date || 0), 'PP p')}</DateWrapper>
}
