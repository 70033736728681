import { PageIndexItem } from '~/types/app'

import type { NestedHeading } from '../types'
import { getRootLevel } from './getRootLevel'
import { getChildNodes } from './getChildNodes'

/**
 * Restructures the provided headings into a nested hierarchy
 */
export function getNestedHeadings(headings: PageIndexItem[]): NestedHeading[] {
	const root: NestedHeading[] = []

	// Preserve order value to aid with childern discovery
	const ordered = headings.map((node, i) => ({ order: i, ...node }))

	// Find all top-level items
	const rootLevel = getRootLevel(headings)
	root.push(...ordered.filter((node) => node.level === rootLevel))

	// Append children to each root node
	root.forEach((node) => {
		node.children = getChildNodes(node, ordered)
	})

	return root
}
