import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Image_Contentful, Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'
import type { ProductCardData } from '~/types/content'

//----- Styling -----//

const Card = styled.div`
	flex: 1 1 auto;
	margin: 0 auto;
	padding: 16px;
	max-width: 256px;
`

const CardImageLink = styled(Anchor)`
	display: block;
	width: 100%;
	text-align: center;
`

const CardDivider = styled.hr`
	margin: 16px 0;
	border: 1px solid ${(p) => p.theme.color.gray[84]};
`

const StoreLink = styled(Anchor)`
	color: ${(p) => p.theme.color.gray[46]};
	font-size: 0.625em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	letter-spacing: ${(p) => p.theme.font.sans.spacing.wide};
	text-transform: uppercase;
`

const ProductLink = styled(Anchor)`
	display: block;
	margin-top: 8px;

	color: ${(p) => p.theme.color.gray[13]};
	font-family: ${(p) => p.theme.font.serif.family};
	font-weight: ${(p) => p.theme.font.serif.weight.bold};
	line-height: 1.25;
`

//----- Component -----//

interface ProductCardProps {
	product: ProductCardData
}

export const ProductCard = (props: ProductCardProps): ReactElement => {
	const { product } = props
	const store = SiteConfig.storeInfo

	return (
		<Card>
			<CardImageLink to={product.targetUrl || ''} aria-label={product.name}>
				{product.image && (
					<Image_Contentful
						src={product.image.url}
						alt={product.image.title || 'Product Image'}
						maxWidth={244}
						maxHeight={180}
						resizeMethod={'pad'}
					/>
				)}
			</CardImageLink>

			<CardDivider />

			<StoreLink to={store.url}>{store.name}</StoreLink>
			<ProductLink to={product.targetUrl || ''}>{product.name}</ProductLink>
		</Card>
	)
}
