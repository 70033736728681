import { FormEvent, ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { useUserContext } from '~/contexts'

import { FormButton, PrivacyNote, ViewTitle } from '../../atoms'

//----- Styling -----//

const List = styled.ul`
	list-style: none;
`
const Item = styled.li`
	& + & {
		margin-top: 1em;
	}
`
const NewsTitle = styled.label`
	position: relative;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	font-style: italic;

	input {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -1.25em;
	}
`
const NewsDescription = styled.div`
	font-size: 0.9em;
`

//----- Component -----//

export const NewsletterView = (props: {
	showNext: () => void
	showPrevious: () => void
}): ReactElement => {
	const user = useUserContext()
	const { externalUrls, newsletters } = SiteConfig

	//----- Component Event Handlers -----//
	function handleNewsToggle(code: string, isChecked: boolean): void {
		const selected = [...user.newsletterSelections]
		const codeIndex = selected.indexOf(code)

		// Add or remove selection based on isChecked
		if (isChecked && codeIndex === -1) selected.push(code)
		if (!isChecked && codeIndex !== -1) selected.splice(codeIndex, 1)

		user.setNewsletterSelections(selected)
	}

	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault()
		props.showNext()
	}

	return (
		<form onSubmit={handleSubmit}>
			<ViewTitle>Sign up for our email newsletters</ViewTitle>

			<List>
				{newsletters.map((newsletter) => (
					<Item key={newsletter.code}>
						<NewsTitle>
							<input
								type="checkbox"
								onChange={(e) =>
									handleNewsToggle(newsletter.code, e.target.checked)
								}
							/>
							{newsletter.name}
						</NewsTitle>
						<NewsDescription>{newsletter.description}</NewsDescription>
					</Item>
				))}
			</List>

			<PrivacyNote>
				By signing up you may also receive occasional special offers and reader
				surveys. We do not sell, rent, or trade our email lists.{' '}
				<Anchor to={externalUrls.privacyPolicy}>
					View our privacy policy.
				</Anchor>
			</PrivacyNote>

			<FormButton type="submit">Register</FormButton>
			<FormButton
				type="reset"
				variant="contrast"
				onClick={() => props.showPrevious()}
			>
				Go Back
			</FormButton>
		</form>
	)
}
