import { ReactElement } from 'react'
import styled from 'styled-components'
import Image from 'next/image'

import { Anchor, Button_Solid, ModalTitle } from '~/components/atoms'
import { Modal } from '~/components/molecules'
import { SiteConfig } from '~/config'
import { useAppContext } from '~/contexts'

import { LoginForm } from './molecules'

//----- Styling -----//

const LogoArea = styled.div`
	margin-bottom: 1.5em;
	text-align: center;
`

const Resources = styled.div`
	display: flex;
	justify-content: space-around;
`

const StyledButton = styled(Button_Solid)`
	margin: 1em 0 0.75em;
	width: 100%;
`

const ForgotAnchor = styled.div`
	margin-bottom: 2em;
	text-align: center;
	font-size: 0.9em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};

	a {
		color: ${(p) => p.theme.color.gray[46]};
	}
`

//----- Component -----//

export const LoginModal = (): ReactElement => {
	const { loginModalIsOpen, setLoginModalOpen, setRegisterModalOpen } =
		useAppContext()
	const { externalUrls, siteInfo } = SiteConfig

	function showRegisterForm() {
		setLoginModalOpen(false)
		setRegisterModalOpen(true)
	}

	return (
		<Modal isOpen={loginModalIsOpen}>
			<LogoArea>
				{/* <Image src={'/assets/globalAssets/siteLogo.png'} alt={siteInfo.title} /> */}
			</LogoArea>
			<ModalTitle>Account Login</ModalTitle>
			<LoginForm />
			<StyledButton variant="contrast" onClick={showRegisterForm}>
				Create an Account
			</StyledButton>
			<ForgotAnchor>
				<Anchor to={externalUrls.resetPassword}>Forgot your Password?</Anchor>
			</ForgotAnchor>
			<Resources>
				<Anchor to={externalUrls.privacyPolicy}>Privacy Policy</Anchor>
				<Anchor to={externalUrls.termsOfUse}>Terms of Use</Anchor>
			</Resources>
		</Modal>
	)
}
