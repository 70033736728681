import { useEffect, useState } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'

import { SiteConfig } from '~/config'

import {
	Button_Bordered,
	HorizontalRule,
	UserHydrator
} from '~/components/atoms'
import { ListingMeta } from './molecules'
import { MarkdownText, PaginatedGrid } from '~/components/organisms'
import { StandardTemplate } from '~/components/templates'
import { PageProvider } from '~/contexts'
import type { ArticleSummaryData } from '~/types/content'
import type { ListingPageProps } from '~/types/page'
import { Analytics, ApiClient, BlogHerAds, getShouldBeDesktop } from '~/util'

import { ListingBottom, ListingRight, ListingTop } from './organisms'
import { getCommonListData } from './util'

//----- Types -----//

// Import from PaginatedGrid?
interface ArticlePages {
	[key: string]: ArticleSummaryData[] | false
}

//----- Component -----//

const { rootUrl } = SiteConfig.siteInfo

export const ListingPage: NextPage<ListingPageProps> = (props) => {
	const { listType, listFile, listRoot, page, content, user } = props
	const commonData = getCommonListData(props)

	const canonicalUrl = rootUrl + (page.path ? page.path : '')

	const Router = useRouter()

	// Initialize with SSR page data, if available
	const [articlePages, setArticlePages] = useState<ArticlePages>({
		[content.pageNum]: content.articles
	})
	const [isLoading, setIsLoading] = useState(false)

	// Calculate page numbers
	const loadedPageNums = Object.keys(articlePages)
		.map((a) => parseInt(a))
		.sort((a, b) => a - b)
	const firstLoadedPage = loadedPageNums[0]
	const lastLoadedPage = loadedPageNums[loadedPageNums.length - 1]

	const [isDesktop, setIsDesktop] = useState(false)

	/**
	 * Fetches data for the current page if it does not exist yet
	 */
	async function fetchPageData(pageNum: number) {
		// If data already exists, do nothing
		if (articlePages[pageNum]) return

		// Set page to 'false' to indicate loading state
		setArticlePages({
			...articlePages,
			[pageNum]: false
		})
		setIsLoading(true)

		// Fetch entries from API
		const result = await ApiClient.getListingPage(listType, page.slug, pageNum)

		// TODO: Would be nice to show an error message if result fails
		if (result) {
			setArticlePages({ ...articlePages, [pageNum]: result })
		}
		setIsLoading(false)
	}

	/**
	 * Adjusts the current path to a new pagination value
	 */
	function setCurrentPage(pageNum: number) {
		const rootPath = listRoot.length ? `/${listRoot}` : ''
		const newPagePath = `${rootPath}/${page.slug}/${pageNum}`

		Router.replace(listFile, newPagePath, { shallow: true })
	}

	function handlePageResize() {
		const shouldBeDesktop = getShouldBeDesktop()
		if (isDesktop !== shouldBeDesktop) setIsDesktop(shouldBeDesktop)
	}

	useEffect(() => {
		Analytics.getInstance()
			.setLoginStatus(user?.email, user?.isLoggedIn, user?.hasMagazineSub)
			.setCategory(page.title || null)
			.pageview(page.path || '/listing-unknown')

		BlogHerAds.initialize('listing')

		window.addEventListener('resize', handlePageResize)
		return () => window.removeEventListener('resize', handlePageResize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<UserHydrator user={user}>
			<PageProvider pageConfig={page}>
				<ListingMeta canonicalUrl={canonicalUrl} />
				<StandardTemplate
					top={
						<ListingTop
							avatarImage={commonData?.image.avatar || null}
							title={page.title}
							isDesktop={isDesktop}
						/>
					}
					right={<ListingRight articles={content.related} />}
					bottom={<ListingBottom products={content.products} />}
					useContainer
				>
					{commonData?.description && (
						<div style={{ marginTop: '-1em', marginBottom: '1.5em' }}>
							<MarkdownText content={commonData.description} />
							<HorizontalRule weight="light" />
						</div>
					)}
					{firstLoadedPage !== 1 && (
						<Button_Bordered
							variant="contrast"
							style={{ display: 'block', margin: '0em auto 0.5em' }}
							onClick={() => {
								fetchPageData(firstLoadedPage - 1)
								setCurrentPage(firstLoadedPage - 1)
							}}
							disabled={isLoading}
						>
							See Previous
						</Button_Bordered>
					)}
					<div style={{ marginTop: '-0.5em' }}>
						<PaginatedGrid
							articlePages={articlePages}
							setCurrentPage={setCurrentPage}
						/>
					</div>
					{lastLoadedPage !== content.lastPageNum && (
						<Button_Bordered
							variant="contrast"
							style={{ display: 'block', margin: '1.5em auto' }}
							onClick={() => fetchPageData(lastLoadedPage + 1)}
							disabled={isLoading}
						>
							See More
						</Button_Bordered>
					)}
				</StandardTemplate>
			</PageProvider>
		</UserHydrator>
	)
}
