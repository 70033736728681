import { SiteConfig } from '~/config'
import type { RichTextContent } from '~/types/content'

const { CUSTOM, insticatorPosition } = SiteConfig.richText

/** Injects an Insticator node at the appropriate location */
export function withInsticator(rootNode: RichTextContent): RichTextContent {
	if (insticatorPosition === -1) return rootNode
	if (rootNode.nodeType !== 'document' || !rootNode.content) return rootNode

	// Handle cases where RichText content length is shorter than insticatorPosition
	const position =
		rootNode.content.length > insticatorPosition
			? insticatorPosition
			: rootNode.content.length - 1

	// No need to insert if the node already exists
	if (rootNode.content[position]?.nodeType === CUSTOM.INSTICATOR)
		return rootNode

	rootNode.content.splice(position, 0, {
		nodeType: CUSTOM.INSTICATOR,
		data: {},
		marks: []
	})

	return rootNode
}
