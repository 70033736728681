import { ReactElement } from 'react'
import styled from 'styled-components'

//----- Styling -----//

export const SponsoredBanner = styled.div`
	position: absolute;
	top: 16px;
	left: 0;
	padding: 0.3em 0.3em 0.2em;

	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	text-transform: uppercase;
	line-height: 1;
	color: ${(p) => p.theme.color.primary.base};

	background: ${(p) => p.theme.color.primary.accent};
	box-shadow: rgba(0, 0, 0, 0.4) 1px 2px 4px 0px;
`

//----- Component -----//

interface SponsoredTagProps {
	template?: string
}

export const SponsoredTag = (props: SponsoredTagProps): ReactElement | null => {
	if (props.template !== 'Sponsored') return null

	return <SponsoredBanner>Sponsored</SponsoredBanner>
}
