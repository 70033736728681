import { ReactElement } from 'react'

import { CaptionedImage } from '~/components/molecules'
import type { RichTextAsset, RichTextContent } from '~/types/content'

//----- Component -----//

export const EmbeddedAsset = (props: RichTextContent): ReactElement | null => {
	if (props.data.target?.sys.type !== 'Asset') return null

	const target = props.data.target as RichTextAsset
	const { description, file, title } = target.fields

	const image = {
		id: target.sys.id,
		title,
		description,
		url: file.url
	}

	return <CaptionedImage image={image} withLine resizeMethod={'pad'} />
}
