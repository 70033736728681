import React, { ReactElement } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import { List_Inline } from '~/components/atoms'
import { SiteConfig } from '~/config'
import { getPath } from '~/util'

import { HeaderAnchor } from '../atoms'

//----- Configuration -----//

const DRAWER_HEIGHT = 55

//----- Styling -----//

const DrawerWrapper = styled.div<{ collapsed: boolean }>`
	display: none;
	position: relative;
	top: ${(p) => (p.collapsed ? -DRAWER_HEIGHT + 'px' : 0)};
	height: ${DRAWER_HEIGHT}px;

	padding: 1.13em 0;

	text-align: center;
	line-height: 1;

	background: ${(p) => p.theme.color.primary.base};
	border-bottom: 3px solid;

	/* Hide behind the Header/Main component */
	z-index: 50;
	transition: top 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		display: block;
	}
`

const CategoryList = styled(List_Inline)`
	& > li + li:before {
		content: '|';
		display: inline-block;

		margin-right: 0.6em;
		transform: translateY(-1px);
	}
`

const CategoryItem = styled.li`
	display: inline-block;

	text-transform: uppercase;

	a {
		color: ${(p) => p.theme.color.gray[46]};

		border-bottom: 2px solid transparent;
		transition:
			color 400ms ease-in-out,
			border-bottom-color 400ms ease-in-out;
		text-decoration: none;

		&:hover,
		&:active,
		&:focus {
			color: ${(p) => p.theme.color.gray[13]};
			border-bottom-color: ${(p) => p.theme.color.gray[13]};
		}
	}
`

//----- Component -----//

export const Drawer = (props: { collapsed: boolean }): ReactElement => (
	<DrawerWrapper collapsed={props.collapsed}>
		<CategoryList gap={0.6} align={'center'}>
			{SiteConfig.categories.map(
				(category, i) =>
					category.visible && (
						<CategoryItem key={i}>
							<Link
								href={getPath.category(category.slug)}
								passHref
								legacyBehavior
							>
								<HeaderAnchor>{category.name}</HeaderAnchor>
							</Link>
						</CategoryItem>
					)
			)}
		</CategoryList>
	</DrawerWrapper>
)
