import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import type { ArticleSummaryData } from '~/types/content'
import { getPath } from '~/util'

//----- Styling -----//

const ListItem = styled.li`
	padding: 1em 0;
	list-style: none;

	border-bottom: 1px solid ${(p) => p.theme.color.primary.base};

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		padding: 0.5em 0;
	}
`

const StyledAnchor = styled(Anchor)`
	font-family: ${(p) => p.theme.font.serif.family};
	font-size: 0.9em;
	color: ${(p) => p.theme.color.primary.base};
`

//----- Component -----//

export const IssueArticle = (props: {
	article: ArticleSummaryData
}): ReactElement => {
	const articlePath = getPath.article(
		props.article.slug,
		props.article.refs.category?.slug
	)

	return (
		<ListItem>
			<StyledAnchor to={articlePath}>{props.article.title}</StyledAnchor>
		</ListItem>
	)
}
