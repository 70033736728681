import { ReactElement } from 'react'
import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import { FullCountdown, SmallCountdown } from './molecules'

//----- Styling -----//

const BannerWrap = styled.div`
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;

	text-transform: uppercase;

	color: ${(p) => p.theme.color.primary.base};
	background: ${(p) => p.theme.color.primary.accent};
	box-shadow: 0px -2px 04px 0px ${fade(0.7, '#000')};
	z-index: ${(p) => p.theme.misc.zIndex.meterBanner};
`

//----- Component -----//

export const MeteringBanner = (): ReactElement => {
	return (
		<BannerWrap>
			<FullCountdown />
			<SmallCountdown />
		</BannerWrap>
	)
}
