import { ReactElement } from 'react'
import styled from 'styled-components'

import { HorizontalRule, Image } from '~/components/atoms'
import { SiteConfig } from '~/config'

import { NewsletterBonus } from './NewsletterBonus'

//----- Styling -----//

const Splash = styled.div`
	font-size: 1.5em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

export const ModalHeader = (): ReactElement => {
	return (
		<section>
			<Image
				src="/assets/globalAssets/paywallPromptLogo.png"
				alt={SiteConfig.siteInfo.splashText}
				maxWidth={118}
			/>
			<Splash>{SiteConfig.siteInfo.splashText}</Splash>

			<NewsletterBonus />
			<HorizontalRule inverted />
		</section>
	)
}
