import { ReactElement } from 'react'
import styled from 'styled-components'

import { NewsletterForm } from '~/components/molecules'

//----- Styling -----//

const CalloutSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding: 1em 0;
	border-top: 1em solid ${(p) => p.theme.color.gray[26]};
	border-bottom: 1px solid ${(p) => p.theme.color.gray[74]};

	@media (min-width: 590px) {
		flex-direction: row;
	}
`

const CalloutText = styled.div`
	margin: 0 0 1em 0;

	@media (min-width: 590px) {
		margin: 0 4em 0 0;
	}
`

const Title = styled.h2`
	margin: 0;
	font-family: ${(p) => p.theme.font.title.family};
	font-weight: ${(p) => p.theme.font.title.weight.regular};
`

const Description = styled.div`
	font-size: 0.75em;
	margin-top: 0.34em;
`

//----- Component -----//

export const NewsletterCallout = (): ReactElement => {
	return (
		<CalloutSection>
			<CalloutText>
				<Title>Newsletter</Title>
				<Description>
					Sign up for our email newsletter for the latest science news
				</Description>
			</CalloutText>
			<NewsletterForm signupSource="Article" />
		</CalloutSection>
	)
}
