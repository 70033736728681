import { ReactElement } from 'react'

import { SiteConfig } from '~/config'
import { useUserContext } from '~/contexts'
import { getPath } from '~/util'

import { SlideoutItem, SlideoutList } from '../../atoms'

interface LinkData {
	href: string
	text: string
}

function getLinkList(
	categories: readonly { slug: string; name: string; visible: boolean }[],
	urls: {
		renew: string
		subscribe: string
		store: string
	},
	hasMagazineSub: boolean
) {
	const linkList: LinkData[] = [
		{
			href: '/magazine',
			text: 'The Magazine'
		},
		{
			href: urls.store,
			text: 'Shop'
		}
	]

	categories.map((category) => {
		if (category.visible)
			linkList.push({
				href: getPath.category(category.slug),
				text: category.name
			})
	})

	linkList.push({
		href: hasMagazineSub ? urls.renew : urls.subscribe,
		text: hasMagazineSub ? 'Renew' : 'Subscribe'
	})

	return linkList
}

export const SlideoutLinkList = (): ReactElement => {
	const { categories = [], externalUrls, storeInfo } = SiteConfig
	const user = useUserContext()

	const urls = {
		store: `${storeInfo.url}?utm_source=${storeInfo.utmSource}&utm_medium=website&utm_campaign=site_header`,
		subscribe: externalUrls.subscribe,
		renew: externalUrls.existingSubscriber
	}

	const linkList = getLinkList(categories, urls, user.hasMagazineSub)

	return (
		<nav>
			<SlideoutList>
				{linkList.map((link, i) => (
					<SlideoutItem key={i} href={link.href}>
						{link.text}
					</SlideoutItem>
				))}
			</SlideoutList>
		</nav>
	)
}
